// material
import { Container, Tab } from '@mui/material'
// hooks
import { useEffect, useState } from 'react'
import useSettings from '../../hooks/useSettings'
//store
// components
import { LoadingButton } from '@mui/lab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useSnackbar } from 'notistack'
import axios, { baseUrl } from 'utils/axios'
import HeaderBreadcrumbs from '../HeaderBreadcrumbs'
import Page from '../Page'
import Factoids from './Factoids'
import MainBlock from './MainBlock'
import SecondBlock from './SecondBlock'

interface Block {
  title: string
  description: string
  image: localFile | null
}

interface localFile extends File {
  fileName: string
  file: string
}

interface AboutPage {
  title: string
  description: string
  firstBlock: Block | null
  secondBlock: Block | null
  factoids: Factoid[]
}

interface Factoid {
  title: string
  description: string
}

export default function AboutBlock() {
  const { themeStretch } = useSettings()
  const { enqueueSnackbar } = useSnackbar()
  const [aboutBlock, setAboutBlock] = useState<AboutPage>({
    title: 'Компания',
    description:
      'Брок - один из лидеров российского рынка металлопроката и металлообработки',
    firstBlock: {
      title: '',
      description: '',
      image: null,
    },
    secondBlock: {
      title: '',
      description: '',
      image: null,
    },
    factoids: [],
  })

  const [dataReceived, setDataReceived] = useState(false)

  const getAboutBlock = async () => {
    const data = await axios.get('/blocks/company')
    if (data.data.firstBlock !== null) {
      ;(data.data.firstBlock as any).image = {
        ...(data.data.firstBlock as any).image,
        fileName: (data.data.firstBlock as any).image.file,
        preview: `${baseUrl}/files/blocks/${
          (data.data.firstBlock as any).image.file
        }`,
      }
    } else {
      ;(data.data.firstBlock as any) = {
        title: '',
        description: '',
        image: null,
      }
    }

    if (!!data.data.secondBlock) {
      ;(data.data.secondBlock as any).image = {
        ...(data.data.secondBlock as any).image,
        fileName: (data.data.secondBlock as any).image.file,
        preview: `${baseUrl}/files/blocks/${
          (data.data.secondBlock as any).image.file
        }`,
      }
    } else {
      ;(data.data.secondBlock as any) = {
        title: '',
        description: '',
        image: null,
      }
    }

    setAboutBlock(data.data)
    setDataReceived(true)
  }

  const putBlock = async () => {
    try {
      const data = await axios.put('/blocks/company', {
        ...aboutBlock,
        firstBlock: {
          ...aboutBlock.firstBlock,
          image: (aboutBlock.firstBlock as any).image?.fileName,
        },
        secondBlock: {
          ...aboutBlock.secondBlock,
          image: (aboutBlock.secondBlock as any).image?.fileName,
        },
      })
      data.data.firstBlock.image = {
        ...data.data.firstBlock.image,
        fileName: data.data.firstBlock.image.file,
        preview: `${baseUrl}/files/blocks/${data.data.firstBlock.image.file}`,
      }
      data.data.secondBlock.image = {
        ...data.data.firstBlock.image,
        fileName: data.data.secondBlock.image.file,
        preview: `${baseUrl}/files/blocks/${data.data.secondBlock.image.file}`,
      }
      setAboutBlock(data.data)
      enqueueSnackbar('Блок о компании сохранен', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('При сохранении возникла ошибка', { variant: 'error' })
    }
  }

  const changeFirstBlock = (val: Block) => {
    setAboutBlock(prev => ({
      ...prev,
      firstBlock: val,
    }))
  }

  const changeSecondBlock = (val: Block) => {
    setAboutBlock(prev => ({
      ...prev,
      secondBlock: val,
    }))
  }

  const changeFactoids = (val: Factoid[]) => {
    setAboutBlock(prev => ({
      ...prev,
      factoids: val,
    }))
  }

  const [currentTab, setCurrentTab] = useState<string | null>('1')

  const handleChangeTab = (value: string | null) => {
    setCurrentTab(value)
  }

  useEffect(() => {
    getAboutBlock()
  }, [])

  return (
    <Page title='Блок "О Компании"'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="О компании" links={[]} />

        {dataReceived === true && (
          <>
            <TabContext value={currentTab as string}>
              <TabList onChange={(e, value) => handleChangeTab(value)}>
                <Tab label="Главный блок" value="1" />
                <Tab label="Второй блок" value="2" />
                <Tab label="Фактоиды" value="3" />
              </TabList>
              <TabPanel value="1">
                <MainBlock
                  changeBlock={changeFirstBlock}
                  propBlock={aboutBlock.firstBlock as Block}
                />
              </TabPanel>
              <TabPanel value="2">
                <SecondBlock
                  changeBlock={changeSecondBlock}
                  propBlock={aboutBlock.secondBlock as Block}
                />
              </TabPanel>
              <TabPanel value="3">
                <Factoids
                  changeFactoids={changeFactoids}
                  factoids={aboutBlock.factoids}
                />
              </TabPanel>
            </TabContext>

            <LoadingButton
              sx={{ width: 'fit-content', mt: 3 }}
              variant="contained"
              onClick={putBlock}
            >
              Сохранить
            </LoadingButton>
          </>
        )}
      </Container>
    </Page>
  )
}
