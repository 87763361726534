import { createSlice } from '@reduxjs/toolkit'
import { IArticle } from '../../../@types/hr/article'
import { IMeta } from '../../../@types/meta'

type StructureState = {
  isLoading: boolean
  error: boolean
  articlesList: {
    data: IArticle[]
    meta: IMeta
  }
  currentArticle?: IArticle
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  articlesList: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
}

const slice = createSlice({
  name: 'hrArticle',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrArticlesListSuccess(state, action) {
      state.isLoading = false
      state.articlesList = action.payload
    },

    getHrArticleSuccess(state, action) {
      state.isLoading = false
      state.currentArticle = action.payload
    },

    changeArticleActiveStatus(state, action) {
      const list = state.articlesList.data.map(item => {
        const is_active =
          item.static_path !== action.payload
            ? item.is_active
            : item.is_active
            ? false
            : true

        return {
          ...item,
          is_active,
        }
      })
      state.articlesList.data = list
    },
    deleteArticleStatus(state, action) {
      const list = state.articlesList.data.filter(
        i => i.static_path !== action.payload,
      )
      state.articlesList.data = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getHrArticlesListSuccess,
  deleteArticleStatus,
  changeArticleActiveStatus,
  getHrArticleSuccess,
} = slice.actions

export default slice.reducer
