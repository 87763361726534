import { createSlice } from '@reduxjs/toolkit';
import {Variable} from "../../@types/variable";

// ----------------------------------------------------------------------

type VariableState = {
  isLoading: boolean;
  error: boolean;
  variableList: Variable[];
};

const initialState: VariableState = {
  isLoading: false,
  error: false,
  variableList: [],
};

const slice = createSlice({
  name: 'variable',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVariableListSuccess(state, action) {
      state.isLoading = false;
      state.variableList = action.payload;
    },

    editVariableSuccess(state, action) {
      const list = state.variableList.map(item => {
        return item.id === action.payload.id ? action.payload : item;
      });
      state.variableList = list;
    }
  }
});

export const { startLoading, hasError, getVariableListSuccess, editVariableSuccess} = slice.actions;

export default slice.reducer;


