import {AppThunk} from "../store";
import axios from "../../utils/axios";
import {
    changeBannersItemActiveStatus,
    deleteBannersItem,
    getBannersItemSuccess,
    getBannersListSuccess,
    hasError,
    startLoading
} from "../slices/banners";

export const getBannerListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/banners');
        dispatch(getBannersListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getBannerThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getBannersItemSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/banners/${id}`);
            dispatch(getBannersItemSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createBannerThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/banners`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editBannerThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/banners/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteBannerThunk = (
    id: number
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/banners/${id}`);
        if(response.data.result) {
            dispatch(deleteBannersItem(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveBannerThunk = (
    id: number,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/banners/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeBannersItemActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateBannerSortThunk = (
    sort: number[],
):AppThunk<Promise<boolean>> => async dispatch => {
    dispatch(startLoading());
    try {
        const params = {
            sortOrder: sort,
        }
        const response = await axios.post(`/banners/update_sort`, params);
        if(response.data){
            dispatch(getBannersListSuccess(response.data));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
