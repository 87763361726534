// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components
import SvgIconStyle from '../../components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
)

const ICONS = {
  structure: getIcon('ic_kanban'),
  user: getIcon('ic_user'),
  variable: getIcon('ic_blog'),
  catalog: getIcon('ic_ecommerce'),
  news: getIcon('ic_calendar'),
  reward: getIcon('ic_banking'),
  provider: getIcon('ic_mail'),
  contact: getIcon('ic_dashboard'),
  robots: getIcon('ic_booking'),
  sitemap: getIcon('ic_booking'),
}

const sidebarConfig = [
  // website management
  // ----------------------------------------------------------------------
  {
    subheader: 'Управление сайтом',
    items: [
      {
        title: 'Главная страница',
        path: PATH_DASHBOARD.main.root,
        icon: ICONS.structure,
      },
      {
        title: 'О компании',
        path: '',
        icon: ICONS.news,
        children: [
          {
            title: 'Страница',
            path: PATH_DASHBOARD.about.root,
            icon: ICONS.news,
          },
          {
            title: 'Блок',
            path: PATH_DASHBOARD.about.block,
            icon: ICONS.news,
          },
          {
            title: 'Поставщики',
            path: PATH_DASHBOARD.suppliers.root,
            icon: ICONS.provider,
          },
          {
            title: 'Награды',
            path: PATH_DASHBOARD.rewards.root,
            icon: ICONS.reward,
          },
        ],
      },

      // {
      //   title: 'Партнеры',
      //   path: PATH_DASHBOARD.partners.root,
      //   icon: ICONS.structure,
      // },
      // {
      //   title: 'Структура сайта',
      //   path: PATH_DASHBOARD.structure.root,
      //   icon: ICONS.structure,
      // },
      {
        title: 'Каталог',
        path: '',
        icon: ICONS.catalog,
        children: [
          {
            title: 'Каталог',
            path: PATH_DASHBOARD.catalog.root,
            icon: ICONS.catalog,
          },
          {
            title: 'Посадочные страницы',
            path: PATH_DASHBOARD.landings.root,
            icon: ICONS.news,
          },
          { title: 'Тэги', path: PATH_DASHBOARD.tag.root, icon: ICONS.news },
        ],
      },
      {
        title: 'Личный кабинет',
        path: '',
        icon: ICONS.user,
        children: [
          {
            title: 'Компании',
            path: PATH_DASHBOARD.company.root,
            icon: ICONS.reward,
          },
          {
            title: 'Клиенты',
            path: PATH_DASHBOARD.client.root,
            icon: ICONS.user,
          },
        ],
      },

      {
        title: 'Пользователи',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },
      // {
      //   title: 'Переменные',
      //   path: PATH_DASHBOARD.variable,
      //   icon: ICONS.variable,
      // },

      { title: 'Новости', path: PATH_DASHBOARD.news.root, icon: ICONS.news },
      {
        title: 'Аналитика',
        path: PATH_DASHBOARD.analytics.root,
        icon: ICONS.news,
      },
      { title: 'Статьи', path: PATH_DASHBOARD.articles.root, icon: ICONS.news },
      {
        title: 'Публикации',
        path: PATH_DASHBOARD.publications.root,
        icon: ICONS.news,
      },
      // { title: 'Услуги', path: PATH_DASHBOARD.services.root, icon: ICONS.news },
      {
        title: 'Услуги',
        path: '',
        icon: ICONS.news,
        children: [
          {
            title: 'Металлообработка',
            path: PATH_DASHBOARD.services.metal,
            icon: ICONS.news,
          },
          // {
          //   title: 'Покупателям',
          //   path: PATH_DASHBOARD.services.customers,
          //   icon: ICONS.news,
          // },
          {
            title: 'Список услуг',
            path: PATH_DASHBOARD.services.list,
            icon: ICONS.news,
          },
        ],
      },
      // { title: 'Баннеры', path: PATH_DASHBOARD.banners.root, icon: ICONS.news },
      // { title: 'Объявления', path: PATH_DASHBOARD.ads.root, icon: ICONS.news },
      { title: 'Отзывы', path: PATH_DASHBOARD.reviews.root, icon: ICONS.news },
      {
        title: 'Контакты',
        path: PATH_DASHBOARD.contact.root,
        icon: ICONS.contact,
      },
      // {
      //   title: 'Вакансии',
      //   path: PATH_DASHBOARD.vacancies.root,
      //   icon: ICONS.news,
      // },
      { title: 'Robots.txt', path: PATH_DASHBOARD.robots, icon: ICONS.robots },
      {
        title: 'Карта сайта',
        path: PATH_DASHBOARD.sitemap,
        icon: ICONS.sitemap,
      },
      { title: 'Логи', path: PATH_DASHBOARD.logs.root, icon: ICONS.news },
    ],
    itemsHr: [
      {
        title: 'Страницы',
        path: PATH_DASHBOARD.hr.page.root,
        icon: ICONS.structure,
      },
      {
        title: 'Мероприятия',
        path: PATH_DASHBOARD.hr.event.root,
        icon: ICONS.news,
      },
      {
        title: 'Статьи',
        path: PATH_DASHBOARD.hr.article.root,
        icon: ICONS.news,
      },
      {
        title: 'Вакансии',
        path: PATH_DASHBOARD.hr.vacancy.root,
        icon: ICONS.news,
      },
      {
        title: 'Менеджер',
        path: PATH_DASHBOARD.hr.manager.root,
        icon: ICONS.user,
      },
      {
        title: 'Истории успеха',
        path: PATH_DASHBOARD.hr.successStory.root,
        icon: ICONS.user,
      },
      {
        title: 'Теги',
        path: PATH_DASHBOARD.hr.specialities.root,
        icon: ICONS.news,
      },
      {
        title: 'Города',
        path: PATH_DASHBOARD.hr.city.root,
        icon: ICONS.news,
      },
      {
        title: 'Направления',
        path: PATH_DASHBOARD.hr.direction.root,
        icon: ICONS.news,
      },
    ],
  },
]

export default sidebarConfig
