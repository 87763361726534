import { filter } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { ICompany } from '../../@types/company'
import { IMeta } from '../../@types/meta'
import { IAction } from '../../@types/pagination'

// // ----------------------------------------------------------------------

interface ICompanyState {
  isLoading: boolean
  error: boolean
  meta: IMeta
  companies: ICompany[]
  companyCurrent: ICompany
}

const initialState: ICompanyState = {
  isLoading: false,
  error: false,
  companies: [],
  meta: {
    current_page: 0,
    from: 0,
    last_page: 0,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  companyCurrent: {
    id: '',
    number: '',
    name: '',
    INN: '',
    KPP: '',
    address: '',
    phone: '',
    email: '',
    admin: {
      id: '',
      firstName: '',
      lastName: '',
      surname: '',
      name: '',
      email: '',
      phone: '',
      role: '',
      position: '',
      active: 0,
      is_banned: 0,
    },
    employees: [],
  },
}
const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    deleteCompany(state, action) {
      const deleteUser = filter(
        state.companies,
        client => client.id !== action.payload,
      )
      state.companies = deleteUser
    },

    getCompaniesSuccess(state, action: IAction<ICompany>) {
      state.isLoading = false
      state.companies = action.payload.data
      state.meta = action.payload.meta
    },

    getСompanyCurrentSuccess(state, action) {
      state.isLoading = false
      state.companyCurrent = action.payload
    },
  },
})

export const {
  startLoading,
  hasError,
  deleteCompany,
  getCompaniesSuccess,
  getСompanyCurrentSuccess,
} = slice.actions

export default slice.reducer
