import {
    Box, Button,
    Card,
    FormHelperText, Grid, Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
    Link, FormControlLabel,
} from "@mui/material";
import React, {useEffect, useState, useRef, ChangeEvent} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {uploadFileThunk} from "../../redux/thunks/files";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {DatePicker, LoadingButton} from "@mui/lab";
import cloudUploadFill from '@iconify/icons-eva/cloud-upload-fill';
import {Icon} from "@iconify/react";
import {useSnackbar} from "notistack";
import Moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import {baseUrl} from "../../utils/axios";
import {FileItem} from "../../@types/files";
import TinyMCEEditor from "../editor/tinymce";
import {createAnalyticsItemThunk, editAnalyticsItemThunk} from "../../redux/thunks/analytics";

type FormValuesProps = {
    title: string;
    description: string;
    date: Date | null;
    active: boolean;
    staticPath: string;
    content: string;
    metaH1: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
};

type Props = {
    onSave: () => void;
};

export default function AnalyticsEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentAnalyticsItem, analyticsList } = useSelector((state: RootState) => state.analytics);

    const isEdit = currentAnalyticsItem !== undefined;

    const [currentTab, setCurrentTab] = useState<string>('general');
    const [content, setContent] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [fileName, setFileName] = useState<FileItem | undefined>(undefined);

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
        staticPath: Yup.string().required('Введите URL страницы'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            description: '',
            date: null,
            active: true,
            staticPath: '',
            content: '',
            metaH1: '',
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            await submitForm();
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        if(currentAnalyticsItem){
            setFieldValue('title', currentAnalyticsItem?.title);
            setFieldValue('description', currentAnalyticsItem?.description);
            setFieldValue('date', currentAnalyticsItem?.date);
            setFieldValue('staticPath', currentAnalyticsItem?.staticPath);
            setFieldValue('active', !!currentAnalyticsItem?.active);
            setFieldValue('content', currentAnalyticsItem?.content);
            setFieldValue('metaH1', currentAnalyticsItem?.metaH1);
            setFieldValue('metaTitle', currentAnalyticsItem?.metaTitle);
            setFieldValue('metaKeywords', currentAnalyticsItem?.metaKeywords);
            setFieldValue('metaDescription', currentAnalyticsItem?.metaDescription);
            const files = currentAnalyticsItem?.files;
            if(files?.length > 0){
                setFileName(files[0]);
            }
            else {
                setFileName(undefined);
            }
            setContent(currentAnalyticsItem?.content);
        }
        else {
            resetForm();
            setFileName(undefined);
            let defaultInd = 1;
            while(analyticsList.find(item => item.staticPath === 'analytics'+defaultInd)){ defaultInd++; }
            setFieldValue('staticPath', 'analytics'+defaultInd);
            setContent('');
        }
    }, [currentAnalyticsItem]);

    const submitForm = async () => {
        const params = {
            ...values,
            active: values.active ? 1 : 0,
            date: values.date ? Moment(values.date).format('YYYY-MM-DD') : null,
            files: fileName !== undefined ? [fileName.file] : [],
            content: content,
        }
        const result = isEdit
            ? await dispatch(editAnalyticsItemThunk(''+currentAnalyticsItem?.id, params))
            : await dispatch(createAnalyticsItemThunk(params));

        if(result) {
            resetForm();
            enqueueSnackbar('Аналитическая статья сохранена', {variant: 'success'});
            onSave();
        }
        else {
            enqueueSnackbar("Ошибка сохранения аналитической статьи", { variant: 'error' });
        }
    }

    const handleClickAttachFile = () => {
        fileInputRef.current?.click();
    };

    const handleDropFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const loadedFileName = await dispatch(uploadFileThunk(file));
            if(loadedFileName){
                setFileName({
                    file: loadedFileName,
                    original_title: file.name,
                });
            }
            else {
                enqueueSnackbar("Ошибка загрузки файла", { variant: 'error' });
            }
        }
    };

    const handleDeleteFile = () => {
        setFileName(undefined);
    }

    const PAGE_TABS = [
        {
            value: 'general',
            title: 'Содержание',
            component:
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('description')}
                                fullWidth
                                multiline
                                minRows={4}
                                type="text"
                                label="Превью"
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                            <Box sx={{ mb: 2 }} />
                            <DatePicker
                                label="Дата"
                                {...getFieldProps('date')}
                                onChange={(date) => setFieldValue('date', date)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={Boolean(touched.date && errors.date)}
                                    />
                                )}
                                inputFormat="yyyy-MM-dd"
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="URL статьи"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                            <Box sx={{ mb: 2 }} />
                            <Stack direction="row" alignItems="center" spacing={3}>
                                <Button
                                    color="inherit"
                                    variant="contained"
                                    onClick={handleClickAttachFile}
                                    startIcon={<Icon icon={cloudUploadFill} />}
                                >
                                     Прикрепить файл
                                </Button>

                                {fileName && (
                                    <>
                                        <Link href={`${baseUrl}/files/analytics/${fileName.file}`} target="_blank" variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>{fileName.original_title}</Link>
                                        <span onClick={(e) => handleDeleteFile()}>
                                            <DeleteIcon onClick={(e) => handleDeleteFile()} sx={{cursor: 'pointer'}}/>
                                        </span>
                                    </>
                                )}

                                <input
                                    ref={fileInputRef}
                                    id="file"
                                    name="file"
                                    type="file"
                                    onChange={handleDropFile}
                                    style={{ display: 'none' }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {currentAnalyticsItem?.createdAt && (
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Создана {Moment(currentAnalyticsItem?.createdAt).format('YYYY-MM-DD HH:mm')} {currentAnalyticsItem?.createdUserName}
                                </Typography>
                            )}
                            {currentAnalyticsItem?.updatedAt && (
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Обновлена {Moment(currentAnalyticsItem?.updatedAt).format('YYYY-MM-DD HH:mm')} {currentAnalyticsItem?.updatedUserName}
                                </Typography>
                            )}
                            <Box sx={{ mb: 2 }} />
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TinyMCEEditor
                                value={values.content}
                                onChange={(val) => setContent(val)}
                            />
                            {touched.content && errors.content && (
                                <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                    {touched.content && errors.content}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Card>
        },
        {
            value: 'seo',
            title: 'SEO',
            component:
                <Card sx={{ p: 3 }}>
                    <TextField
                        {...getFieldProps('metaH1')}
                        fullWidth
                        type="text"
                        label="Заголовок H1"
                        error={Boolean(touched.metaH1 && errors.metaH1)}
                        helperText={touched.metaH1 && errors.metaH1}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaTitle')}
                        fullWidth
                        type="text"
                        label="Meta Title"
                        error={Boolean(touched.metaTitle && errors.metaTitle)}
                        helperText={touched.metaTitle && errors.metaTitle}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaKeywords')}
                        fullWidth
                        type="text"
                        label="Meta Keywords"
                        error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                        helperText={touched.metaKeywords && errors.metaKeywords}
                    />
                    <Box sx={{ mb: 2 }} />
                    <TextField
                        {...getFieldProps('metaDescription')}
                        fullWidth
                        type="text"
                        multiline
                        minRows={4}
                        label="Meta Description"
                        error={Boolean(touched.metaDescription && errors.metaDescription)}
                        helperText={touched.metaDescription && errors.metaDescription}
                    />
                </Card>
        }
    ];

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Tabs
                        value={currentTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        onChange={(e, value) => setCurrentTab(value)}
                    >
                        {PAGE_TABS.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.value}
                                label={tab.title}
                                value={tab.value}
                            />
                        ))}
                    </Tabs>

                    {PAGE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}

                    <Box sx={{ mb: 5 }} />

                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Сохранить
                    </LoadingButton>
                    <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                        Отмена
                    </Button>
                </Form>
            </FormikProvider>
        </>
    );
}