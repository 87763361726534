import {AppThunk} from "../store";
import axios from "../../utils/axios";
import { startLoading, hasError, getVacanciesListSuccess, getVacanciesItemSuccess, changeVacanciesItemActiveStatus, deleteVacanciesItem, getSpecialityListSuccess, getSpecialitySuccess, changeSpecialityActiveStatus, deleteSpeciality } from "redux/slices/vacancies";

export const getVacanciesListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/vacancies');
        dispatch(getVacanciesListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getVacanciesItemThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getVacanciesItemSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/vacancies/${id}`);
            dispatch(getVacanciesItemSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createVacanciesItemThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/vacancies`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editVacanciesItemThunk = (
    newsItemId: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/vacancies/${newsItemId}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteVacanciesItemThunk = (
    itemId: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/vacancies/${itemId}`);
        if(response.data.result) {
            dispatch(deleteVacanciesItem(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveVacanciesItemThunk = (
    itemId: string,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/vacancies/${itemId}/activate`, params);
        if(response.data.result){
            dispatch(changeVacanciesItemActiveStatus(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const getSpecialityListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/specializations');
        dispatch(getSpecialityListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}
export const getSpecialityThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getSpecialitySuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/specializations/${id}`);
            dispatch(getSpecialitySuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createSpecialityThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/specializations`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editSpecialityThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/specializations/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteSpecialityThunk = (
    id: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/specializations/${id}`);
        if(response.data.result) {
            dispatch(deleteSpeciality(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveSpecialityThunk = (
    id: string,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/specializations/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeSpecialityActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}