import {AppThunk} from "../store";
import axios from "../../utils/axios";
import { startLoading, hasError, getPublicationsListSuccess, getPublicationsItemSuccess, changePublicationsItemActiveStatus, deletePublicationsItem } from "redux/slices/publications";

export const getPublicationsListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/publications');
        dispatch(getPublicationsListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getPublicationsItemThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getPublicationsItemSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/publications/${id}`);
            dispatch(getPublicationsItemSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createPublicationsItemThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/publications`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editPublicationsItemThunk = (
    itemId: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/publications/${itemId}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deletePublicationsItemThunk = (
    itemId: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/publications/${itemId}`);
        if(response.data.result) {
            dispatch(deletePublicationsItem(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActivePublicationsItemThunk = (
    itemId: string,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/publications/${itemId}/activate`, params);
        if(response.data.result){
            dispatch(changePublicationsItemActiveStatus(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
