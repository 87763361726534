import { createSlice } from '@reduxjs/toolkit'
import { Category } from '../../@types/tag'
import { IMeta } from '../../@types/meta'

// ----------------------------------------------------------------------

type TagState = {
  isLoading: boolean
  error: boolean
  meta: IMeta
  categoryList: Category[]
  grupList: Category[]
  currentCategory?: Category
}

const initialState: TagState = {
  isLoading: false,
  error: false,
  meta: {
    current_page: 1,
    from: 0,
    last_page: 1,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  categoryList: [],
  grupList: [],
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false
      state.categoryList = action.payload
    },

    getGrupListSuccess(state, action) {
      state.grupList = action.payload
    },
  },
})

export const {
  startLoading,
  hasError,
  getCategoryListSuccess,
  getGrupListSuccess,
} = slice.actions

export default slice.reducer
