// material
import {
  Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import BannersTable from "../../../components/banners/BannersTable";

export default function BannersList() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Список баннеров">
      <Container maxWidth={themeStretch ? false : 'lg'}>

        <HeaderBreadcrumbs
          heading="Список баннеров"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Баннеры'},
          ]}
          action={
              <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.banners.create}`}
                  startIcon={<Icon icon={plusFill} />}
              >
                Баннер
              </Button>
          }
        />

        <BannersTable />

      </Container>
    </Page>
  );
}
