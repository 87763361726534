import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import AnalyticsEdit from "../../../components/analytics/AnalyticsEdit";
import {getAnalyticsItemThunk} from "../../../redux/thunks/analytics";
// ----------------------------------------------------------------------

export default function AnalyticsCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, currentAnalyticsItem } = useSelector((state: RootState) => state.analytics);

    const { id = 0 } = useParams();
    const isEdit = currentAnalyticsItem !== undefined;

    useEffect(() => {
        dispatch(getAnalyticsItemThunk(+id));
    }, [dispatch]);

    const handleSave = () => {
        navigate(PATH_DASHBOARD.analytics.root);
    }

    return (
        <DataLoadingPage
            loading={isLoading}
            title='Редактирование аналитической статьи'
            heading={!isEdit ? 'Создание статьи' : 'Редактирование статьи'}
            links={[
                { name: 'Главная', href: PATH_DASHBOARD.root },
                { name: 'Аналитические статьи', href: PATH_DASHBOARD.analytics.root },
                { name: !isEdit ? 'Новая статья' : (currentAnalyticsItem?.title || '') }
            ]}
        >

             <AnalyticsEdit
                 onSave={handleSave}
             />

        </DataLoadingPage>
    );
}
