import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getRegionListThunk} from "../../../redux/thunks/contact";
import {getAdsThunk} from "../../../redux/thunks/ads";
import AdsEdit from "../../../components/ads/AdsEdit";

// ----------------------------------------------------------------------

export default function AdsCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, currentAdsItem } = useSelector((state: RootState) => state.ads);

  const { id = 0 } = useParams();
  const isEdit = currentAdsItem !== undefined;

  useEffect(() => {
      dispatch(getAdsThunk(+id));
      dispatch(getRegionListThunk());
  }, [dispatch]);

  const handleSave = () => {
    navigate(PATH_DASHBOARD.ads.root);
  }

  return (
      <DataLoadingPage
        loading={isLoading}
        title='Редактирование объявления'
        heading={!isEdit ? 'Создание объявления' : 'Редактирование объявления'}
        links={[
          { name: 'Главная', href: PATH_DASHBOARD.root },
          { name: 'Объявления', href: PATH_DASHBOARD.ads.root },
          { name: !isEdit ? 'Новое объявление' : (currentAdsItem?.title || '') }
        ]}
      >
        <AdsEdit
            onSave={handleSave}
        />
      </DataLoadingPage>
  );
}
