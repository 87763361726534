import {AppThunk} from "../store";
import axios from "../../utils/axios";
import {
    changeAdsItemActiveStatus,
    deleteAdsItem,
    getAdsItemSuccess,
    getAdsListSuccess,
    hasError,
    startLoading
} from "../slices/ads";

export const getAdsListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/advertisements');
        dispatch(getAdsListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getAdsThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getAdsItemSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/advertisements/${id}`);
            dispatch(getAdsItemSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createAdsThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/advertisements`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editAdsThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/advertisements/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteAdsThunk = (
    id: number
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/advertisements/${id}`);
        if(response.data.result) {
            dispatch(deleteAdsItem(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveAdsThunk = (
    id: number,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/advertisements/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeAdsItemActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateAdsrSortThunk = (
    sort: number[],
):AppThunk<Promise<boolean>> => async dispatch => {
    dispatch(startLoading());
    try {
        const params = {
            sortOrder: sort,
        }
        const response = await axios.post(`/advertisements/update_sort`, params);
        if(response.data){
            dispatch(getAdsListSuccess(response.data));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
