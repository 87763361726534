// material
import {
    Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ArticlesTable from "../../../components/articles/ArticlesTable";

export default function ArticlesList() {
    const { themeStretch } = useSettings();

    return (
        <Page title="Cтатьи">
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading="Cтатьи"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        { name: 'Cтатьи'},
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={`${PATH_DASHBOARD.articles.create}`}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Статья
                        </Button>
                    }
                />

                <ArticlesTable />

            </Container>
        </Page>
    );
}
