import {AppThunk} from "../store";
import axios from "../../utils/axios";
import {
    startLoading, hasError,
    getRegionListSuccess, getRegionSuccess, deleteRegion, changeRegionActiveStatus,
    getContactListSuccess, getContactSuccess, deleteContact, changeContactActiveStatus
} from "../slices/contact";

export const getRegionListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/contacts/regions');
        dispatch(getRegionListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getRegionThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getRegionSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/contacts/regions/${id}`);
            dispatch(getRegionSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createRegionThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/contacts/regions`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editRegionThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/contacts/regions/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteRegionThunk = (
    id: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/contacts/regions/${id}`);
        if(response.data.result) {
            dispatch(deleteRegion(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveRegionThunk = (
    id: number,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/contacts/regions/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeRegionActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateRegionSortThunk = (
    sort: number[],
):AppThunk<Promise<boolean>> => async dispatch => {
    dispatch(startLoading());
    try {
        const params = {
            sortOrder: sort,
        }
        const response = await axios.post(`/contacts/regions/update_sort`, params);
        if(response.data){
            dispatch(getRegionListSuccess(response.data));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const getContactListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/contacts/branches');
        dispatch(getContactListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getContactThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getContactSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/contacts/branches/${id}`);
            dispatch(getContactSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createContactThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/contacts/branches`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editContactThunk = (
    id: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/contacts/branches/${id}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteContactThunk = (
    id: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/contacts/branches/${id}`);
        if(response.data.result) {
            dispatch(deleteContact(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveContactThunk = (
    id: number,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/contacts/branches/${id}/activate`, params);
        if(response.data.result){
            dispatch(changeContactActiveStatus(id));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
