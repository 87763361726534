import React, { useEffect } from 'react';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
import {PATH_DASHBOARD} from "../../routes/paths";
import {useSnackbar} from "notistack";
import DnDTable from "../dndtable/DnDTable";
import {
    changeActiveBannerThunk,
    deleteBannerThunk,
    getBannerListThunk,
    updateBannerSortThunk
} from "../../redux/thunks/banners";
import {updateBannersSortOrder} from "../../redux/slices/banners";

export default function BannersTable() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { bannersList } = useSelector((state: RootState) => state.banners);

    useEffect(() => {
        dispatch(getBannerListThunk());
    }, [dispatch]);

    const handleOnDelete = async (id: number) => {
        const result = await dispatch(deleteBannerThunk(id));
        if(result){
            enqueueSnackbar('Баннер удален', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка удаления баннера", { variant: 'error' });
        }
    }

    const handleOnChangeActive = async (id: number, checked: boolean) => {
        const result = await dispatch(changeActiveBannerThunk(id, checked));
        if(result) {
            enqueueSnackbar('Активность баннера изменена', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на изменение активности баннера", { variant: 'error' });
        }
    }

    const handleOnChangeSort = async (sort: number[]) => {
        dispatch(updateBannersSortOrder(sort));
        const result = await dispatch(updateBannerSortThunk(sort));
        if(result) {
            enqueueSnackbar('Порядок сохранен', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на сохранение порядка", { variant: 'error' });
        }
    }

    return (
        <DnDTable
            list={bannersList}
            showRegions={true}
            handleOnDelete={handleOnDelete}
            handleOnChangeActive={handleOnChangeActive}
            handleOnChangeSort={handleOnChangeSort}
            itemPath={PATH_DASHBOARD.banners.root}
        />
    );
}
