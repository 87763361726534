import { createSlice } from '@reduxjs/toolkit'
import { Category, FullTagItem, tagItem, itemList } from '../../@types/tag'
import { IMeta } from '../../@types/meta'

// ----------------------------------------------------------------------

type TagState = {
  isLoading: boolean
  error: boolean
  meta: IMeta
  tagList: tagItem[]
  currentTagItem?: FullTagItem
  categoryList: Category[]
  grupList: Category[]
  itemList: itemList[]
  currentCategory?: Category
}

const initialState: TagState = {
  isLoading: false,
  error: false,
  meta: {
    current_page: 1,
    from: 0,
    last_page: 1,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  tagList: [],
  categoryList: [],
  grupList: [],
  itemList: [],
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getTagListSuccess(state, action) {
      state.isLoading = false
      state.tagList = action.payload
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false
      state.categoryList = action.payload
    },
    getGrupListSuccess(state, action) {
      state.grupList = action.payload
    },
    getItemListSuccess(state, action) {
      state.isLoading = false
      state.itemList = action.payload.data
      state.meta = action.payload.meta
    },

    getTagItemSuccess(state, action) {
      state.isLoading = false
      state.currentTagItem = action.payload
    },

    changeTagItemActiveStatus(state, action) {
      const list = state.tagList.map(tagItem => {
        const active =
          tagItem.id !== +action.payload
            ? tagItem.active
            : tagItem.active
            ? 0
            : 1
        return {
          ...tagItem,
          active,
        }
      })
      state.tagList = list
    },

    deleteTagItem(state, action) {
      const list = state.tagList.filter(
        tagItem => tagItem.id !== +action.payload,
      )
      state.tagList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getTagListSuccess,
  getTagItemSuccess,
  changeTagItemActiveStatus,
  deleteTagItem,
  getCategoryListSuccess,
  getGrupListSuccess,
  getItemListSuccess,
} = slice.actions

export default slice.reducer
