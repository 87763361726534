import { createSlice } from '@reduxjs/toolkit'
import { ICity } from '../../../@types/hr/cities'

type StructureState = {
  isLoading: boolean
  error: boolean
  citiesList: ICity[]
  currentCity?: ICity
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  citiesList: [],
}

const slice = createSlice({
  name: 'hrCities',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrCitiesListSuccess(state, action) {
      state.isLoading = false
      state.citiesList = action.payload
    },

    getHrCitySuccess(state, action) {
      state.isLoading = false
      state.currentCity = action.payload
    },

    deleteHrCityStatus(state, action) {


      const list = state.citiesList.filter(
        i => i.id.toString() !== action.payload.toString(),
      )
      state.citiesList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getHrCitiesListSuccess,
  getHrCitySuccess,
  deleteHrCityStatus
} = slice.actions

export default slice.reducer
