import { createSlice } from '@reduxjs/toolkit'
import { IManager } from '../../../@types/hr/manager'

type StructureState = {
  isLoading: boolean
  error: boolean
  managerList: IManager[]
  currentManager?: IManager
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  managerList: [],
}

const slice = createSlice({
  name: 'hrManager',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrManagerListSuccess(state, action) {
      state.isLoading = false
      state.managerList = action.payload
    },

    getHrManagerSuccess(state, action) {
      state.isLoading = false
      state.currentManager = action.payload
    },

    deleteManagerStatus(state, action) {
      const list = state.managerList.filter(
        i => i.id !== action.payload,
      )
      state.managerList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  deleteManagerStatus,
  getHrManagerListSuccess,
  getHrManagerSuccess,
} = slice.actions

export default slice.reducer
