import { createSlice } from '@reduxjs/toolkit'
import {
  Category,
  ExportCategory,
  ExportGroup,
  ExportStock,
  FullCategory,
  IFilterCtegory,
  ItemList,
} from '../../@types/catalog'
import { FullItem } from '../../@types/item'

// ----------------------------------------------------------------------

type CatalogState = {
  isLoading: boolean
  error: boolean
  categoryList: Category[]
  currentCategory?: FullCategory
  filtersList?: IFilterCtegory[]
  exportCategoryList: ExportCategory[]
  exportGroupList: ExportGroup[]
  exportStockList: ExportStock[]
  itemsList: ItemList[]
  item?: FullItem
}

const initialState: CatalogState = {
  isLoading: false,
  error: false,
  categoryList: [],
  exportCategoryList: [],
  exportGroupList: [],
  exportStockList: [],
  itemsList: [],
}

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getCategoryListSuccess(state, action) {
      state.isLoading = false
      state.categoryList = action.payload
    },

    getCategorySuccess(state, action) {
      state.isLoading = false
      state.currentCategory = action?.payload
      state.filtersList = action.payload?.filters
    },

    sortOrderUpdated(state) {
      state.isLoading = false
    },

    getExportCategoryListSuccess(state, action) {
      state.isLoading = false
      state.exportCategoryList = action.payload
    },

    getExportGroupListSuccess(state, action) {
      state.isLoading = false
      state.exportGroupList = action.payload
    },

    getFiltersListSuccess(state, action) {
      state.isLoading = false
      state.filtersList = action.payload
    },

    getExportStockListSuccess(state, action) {
      state.isLoading = false
      state.exportStockList = action.payload
    },
    getItemsListSuccess(state, action) {
      state.isLoading = false
      state.itemsList = action.payload
    },
    getItemSuccess(state, action) {
      state.isLoading = false
      state.item = action.payload
    },
    updateFiltersSortOrder(state, action) {
      const sort: number[] = action.payload
      const list: IFilterCtegory[] = []
      sort.forEach(value => {
        const item =
          state.filtersList && state.filtersList.find(item => item.id === value)
        if (item !== undefined) list.push(item)
      })
      state.filtersList = list
    },

    changeFiltersItemActiveStatus(state, action) {
      const list =
        state.filtersList &&
        state.filtersList.map(item => {
          const itemFilter = item.id === action.payload

          if (itemFilter) {
            item.isActive = item.isActive ? 0 : 1
          }

          return {
            ...item,
            itemFilter,
          }
        })

      state.filtersList = list
    },
  },
})

export const {
  changeFiltersItemActiveStatus,
  getFiltersListSuccess,
  updateFiltersSortOrder,
  startLoading,
  hasError,
  getCategoryListSuccess,
  getCategorySuccess,
  sortOrderUpdated,
  getExportCategoryListSuccess,
  getExportGroupListSuccess,
  getExportStockListSuccess,
  getItemsListSuccess,
  getItemSuccess,
} = slice.actions

export default slice.reducer
