import { createSlice } from '@reduxjs/toolkit';
import { IHrPage } from '../../../@types/hr/page';


type StructureState = {
  isLoading: boolean;
  error: boolean;
  pageList: IHrPage[];
  currentPage?: IHrPage;
};

const initialState: StructureState = {
  isLoading: false,
  error: false,
  pageList: [],
};

const slice = createSlice({
  name: 'hrPage',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getHrPageListSuccess(state, action) {
      state.isLoading = false;
      state.pageList = action.payload;
    },

    getHrPageSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload;
    },
  }
});

export const { startLoading, hasError, getHrPageListSuccess, getHrPageSuccess } = slice.actions;

export default slice.reducer;
