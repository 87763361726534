import { createSlice } from '@reduxjs/toolkit';
import {AnalyticsItem, FullAnalyticsItem} from "../../@types/analytics";

// ----------------------------------------------------------------------

type AnalyticsState = {
  isLoading: boolean;
  error: boolean;
  analyticsList: AnalyticsItem[];
  currentAnalyticsItem?: FullAnalyticsItem;
};

const initialState: AnalyticsState = {
  isLoading: false,
  error: false,
  analyticsList: [],
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAnalyticsListSuccess(state, action) {
      state.isLoading = false;
      state.analyticsList = action.payload;
    },

    getAnalyticsItemSuccess(state, action) {
      state.isLoading = false;
      state.currentAnalyticsItem = action.payload;
    },

    changeAnalyticsItemActiveStatus(state, action) {
      const list = state.analyticsList.map(analyticsItem => {
        const active = (analyticsItem.id !== +action.payload) ? analyticsItem.active : (analyticsItem.active ? 0 : 1);
        return {
          ... analyticsItem,
          active
        }
      });
      state.analyticsList = list;
    },

    deleteAnalyticsItem(state, action) {
      const list = state.analyticsList.filter((analyticsItem) => analyticsItem.id !== +action.payload);
      state.analyticsList = list;
    },
  }
});

export const { startLoading, hasError,
  getAnalyticsListSuccess, getAnalyticsItemSuccess, changeAnalyticsItemActiveStatus, deleteAnalyticsItem
} = slice.actions;

export default slice.reducer;


