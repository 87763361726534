import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useCallback } from 'react'
import { RootState, useDispatch, useSelector } from '../../redux/store'
import { uploadFileThunk } from '../../redux/thunks/files'
import { getCategoryThunk, getGrupThunk } from 'redux/thunks/tag'

import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { baseUrl } from '../../utils/axios'
import {
  createServiceThunk,
  editServiceThunk,
} from '../../redux/thunks/services'
import TinyMCEEditor from '../editor/tinymce'
import { UploadSingleFile } from '../upload'
import { cloneDeep } from 'lodash'
import ServicesFaq from './ServicesFaq'
import ServicesEquipment from './ServicesEquipment'
import ServicesWork from './ServicesWorks'
import ServicesBlocks from './ServicesBlocks'
import axios from 'utils/axios'
import MainAdvantages from 'components/main-page/MainAdvantages'
import { FullService } from '../../@types/services'

type FormValuesProps = {
  title: string
  sortOrder: number
  type: string
  active: boolean
  staticPath: string
  content: string
  metaH1: string
  metaTitle: string
  metaKeywords: string
  metaDescription: string
  blocks: any[]
}

interface Faq {
  question: string
  answer: string
  position: number
  active: boolean
}
interface Work {
  title: string
  category: string
  image: localFile | null
  position: number
  active: boolean
}

interface Equipment {
  title: string
  description: string
  active: boolean
  position: number
  image: localFile | null
}

interface localFile extends File {
  fileName: string
  file: string
}

type Props = {
  onSave: (id: number | null) => void
  slug: string | null
  noUrl?: boolean
  metal?: boolean
}

export default function ServicesEdit({
  onSave,
  slug,
  noUrl = false,
  metal = false,
}: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const { currentService } = useSelector((state: RootState) => state.services)
  const { regionList, contactList } = useSelector(
    (state: RootState) => state.contact,
  )
  const { categoryList } = useSelector((state: RootState) => state.tag)

  const isEdit = currentService !== undefined

  const [currentTab, setCurrentTab] = useState<string>('general')
  const [content, setContent] = useState<string>('')
  const [file, setFile] = useState<File | string | null>(null)
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const [selectedRegions, setSelectedRegions] = useState<Set<number>>(new Set())
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(
    new Set(),
  )
  const [dataReceived, setDataReceived] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState<Set<string>>(new Set())
  const [selectedBranches, setSelectedBranches] = useState<Set<number>>(
    new Set(),
  )

  const EditSchema = Yup.object().shape({
    title: Yup.string().required('Введите заголовок'),
  })

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      title: '',
      type: '',
      active: true,
      sortOrder: 0,
      staticPath: '',
      content: '',
      metaH1: '',
      metaTitle: '',
      metaKeywords: '',
      metaDescription: '',
      blocks: [],
    },
    validationSchema: EditSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const params = {
        ...values,
        active: values.active ? 1 : 0,
        files: fileName !== undefined ? [fileName] : [],
        content: content,
        regions: Array.from(selectedRegions),
        categories: Array.from(selectedCategories),
        groups: Array.from(selectedGroups),
        branches: Array.from(selectedBranches),
      }
      const resWorks = isEdit ? (work.length ? await putWorks() : true) : true
      const resEquipment = isEdit
        ? equipment.length
          ? await putEquipment()
          : true
        : true
      const resFaq = isEdit ? (faq.length ? await putFaq() : true) : true

      const result = isEdit
        ? await dispatch(editServiceThunk('' + currentService?.id, params))
        : await dispatch(createServiceThunk(params))

      setSubmitting(false)
      if (result.result && resWorks && resEquipment && resFaq) {
        enqueueSnackbar('Услуга сохранена', { variant: 'success' })
        onSave(result.id)
      } else {
        enqueueSnackbar('Ошибка сохранения услуги', { variant: 'error' })
      }
    },
  })

  const {
    errors,
    values,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
  } = formik

  const [work, setWork] = useState<Work[]>([])

  const getWorks = async () => {
    const data = await axios.get(`/works?slug=${slug}`)
    setWork(
      data.data.map((item: Work) => {
        return {
          ...item,
          image: {
            ...(item.image as localFile),
            fileName: (item.image as localFile).file,
            preview: `${baseUrl}/files/works/${(item.image as localFile).file}`,
          },
        }
      }),
    )
  }

  const putWorks = async () => {
    try {
      await axios.put('/works', {
        slug: slug!.toString(),
        works: work.map((item: Work) => {
          return {
            ...item,
            image: (item.image as localFile).fileName,
          }
        }),
      })
      enqueueSnackbar('"Наши работы" сохранены', { variant: 'success' })
      return true
    } catch (error) {
      enqueueSnackbar('Ошибка сохранения "Наши работы"', { variant: 'error' })
      return false
    }
  }

  const changeWork = (block: Work[]) => {
    setWork(block)
  }

  const [faq, setFaq] = useState<Faq[]>([])

  const getFaq = async () => {
    const data = await axios.get(`/questions?slug=${slug}`)
    setFaq(data.data)
  }

  const putFaq = async () => {
    try {
      await axios.put('/questions', {
        slug: slug!.toString(),
        questionAnswers: faq,
      })
      enqueueSnackbar('"FAQ" сохранены', { variant: 'success' })
      return true
    } catch (error) {
      enqueueSnackbar('Ошибка сохранения "FAQ"', { variant: 'error' })
      return false
    }
  }

  const changeFaq = (block: Faq[]) => {
    setFaq(block)
  }

  const [equipment, setEquipment] = useState<Equipment[]>([])

  const changeEquipment = (block: Equipment[]) => {
    setEquipment(block)
  }

  const getEquipment = async () => {
    const data = await axios.get(`/equipments?slug=${slug}`)
    setEquipment(
      data.data.map((item: Equipment) => {
        return {
          ...item,
          image: {
            ...(item.image as localFile),
            fileName: (item.image as localFile).file,
            preview: `${baseUrl}/files/equipments/${
              (item.image as localFile).file
            }`,
          },
        }
      }),
    )
  }

  const putEquipment = async () => {
    try {
      await axios.put('/equipments', {
        slug: slug!.toString(),
        equipments: equipment.map((item: Equipment) => {
          return {
            ...item,
            image: (item.image as localFile).fileName,
          }
        }),
      })

      enqueueSnackbar('"Наше оборудование" сохранены', { variant: 'success' })
      return true
    } catch (error) {
      enqueueSnackbar('Ошибка сохранения "Наше оборудование"', {
        variant: 'error',
      })
      return false
    }
  }

  const handleDropFile = useCallback(async acceptedFiles => {
    const file = acceptedFiles[0]
    if (file) {
      const loadedFileName = await dispatch(uploadFileThunk(file))
      if (loadedFileName) {
        setFileName(loadedFileName)
        setFile({
          ...file,
          preview: URL.createObjectURL(file),
        })
      } else {
        enqueueSnackbar('Ошибка загрузки файла', { variant: 'error' })
      }
    }
  }, [])

  const handleRemoveImage = useCallback(() => {
    setFile(null)
    setFileName(undefined)
  }, [])

  const handleRegionChange = (regionId: number, checked: boolean) => {
    const newSelectedRegions = cloneDeep(selectedRegions)
    if (regionId === 0) {
      if (checked) {
        newSelectedRegions.clear()
      }
    } else {
      if (checked) {
        newSelectedRegions.add(regionId)
      } else {
        newSelectedRegions.delete(regionId)
      }
    }

    setSelectedRegions(newSelectedRegions)
  }

  const handleCategoryChange = (categoryId: string, checked: boolean) => {
    const newSelectedCategories = cloneDeep(selectedCategories)
    if (categoryId === '0') {
      if (checked) {
        newSelectedCategories.clear()
      }
    } else {
      if (checked) {
        newSelectedCategories.add(categoryId)
      } else {
        newSelectedCategories.delete(categoryId)
      }
    }

    setSelectedCategories(newSelectedCategories)
  }

  const handleGroupChange = (groupId: string, checked: boolean) => {
    const newSelectedGroups = cloneDeep(selectedGroups)
    if (groupId === '0') {
      if (checked) {
        newSelectedGroups.clear()
      }
    } else {
      if (checked) {
        newSelectedGroups.add(groupId)
      } else {
        newSelectedGroups.delete(groupId)
      }
    }

    setSelectedGroups(newSelectedGroups)
  }

  const handleBranchChange = (branchId: number, checked: boolean) => {
    const newSelectedBranches = cloneDeep(selectedBranches)
    if (branchId === 0) {
      if (checked) {
        newSelectedBranches.clear()
      }
    } else {
      if (checked) {
        newSelectedBranches.add(branchId)
      } else {
        newSelectedBranches.delete(branchId)
      }
    }

    setSelectedBranches(newSelectedBranches)
  }

  const typeList = [
    {
      title: 'Металлообработка',
      value: 'metal',
    },
    {
      title: 'Покупателям',
      value: 'customers',
    },
    {
      title: 'Сервис',
      value: 'service',
    },
  ]

  const handleChangeType = (e: any) => {
    setFieldValue('type', e.target.value)
  }

  const changeBlocks = (val: any) => {
    setFieldValue('blocks', val)
  }

  const PAGE_TABS = slug
    ? [
        {
          value: 'general',
          title: 'Содержание',
          component: (
            <Card sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    {...getFieldProps('title')}
                    fullWidth
                    type="text"
                    label="Заголовок"
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  {!noUrl && (
                    <>
                      <Box sx={{ mb: 2 }} />
                      <TextField
                        {...getFieldProps('staticPath')}
                        fullWidth
                        type="text"
                        label="URL услуги"
                        error={Boolean(touched.staticPath && errors.staticPath)}
                        helperText={touched.staticPath && errors.staticPath}
                      />
                    </>
                  )}

                  <Box sx={{ mb: 2 }} />
                  <FormControl fullWidth>
                    <InputLabel>Тип услуги</InputLabel>
                    <Select
                      value={values.type}
                      label="Тип услуги"
                      onChange={e => handleChangeType(e)}
                    >
                      {typeList.map(type => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={{ mb: 2 }} />
                  <TinyMCEEditor
                    value={values.content}
                    onChange={val => setContent(val)}
                  />
                  <ServicesBlocks
                    changeBlocks={changeBlocks}
                    blocksProp={values.blocks}
                    metal={metal}
                  />
                  {touched.content && errors.content && (
                    <FormHelperText
                      error
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {touched.content && errors.content}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        {...getFieldProps('active')}
                        checked={values.active}
                      />
                    }
                    label={
                      <>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}
                        >
                          Активность
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  />
                  <Box sx={{ mb: 2 }} />
                  <UploadSingleFile file={file} onDrop={handleDropFile} />
                  {file && (
                    <Button color="error" onClick={handleRemoveImage}>
                      удалить
                    </Button>
                  )}
                  <Box sx={{ mb: 2 }} />
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Регионы:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRegions.size === 0}
                          onChange={e =>
                            handleRegionChange(0, e.target.checked)
                          }
                        />
                      }
                      label="Все города"
                    />
                    {regionList.map(region => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedRegions.has(region.id)}
                            onChange={e =>
                              handleRegionChange(region.id, e.target.checked)
                            }
                          />
                        }
                        label={region.title}
                        key={region.id}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Филиалы:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedBranches.size === 0}
                          onChange={e =>
                            handleBranchChange(0, e.target.checked)
                          }
                        />
                      }
                      label="Все филиалы"
                    />
                    {contactList.map(branch => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedBranches.has(branch.id)}
                            onChange={e =>
                              handleBranchChange(branch.id, e.target.checked)
                            }
                          />
                        }
                        label={branch.title}
                        key={branch.id}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Категории:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategories.size === 0}
                          onChange={e =>
                            handleCategoryChange('0', e.target.checked)
                          }
                        />
                      }
                      label="Все категории"
                    />
                    {categoryList
                      .filter(i => i.title !== 'Производство')
                      .filter(i => i.title !== 'Металлообработка')
                      .map(category => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCategories.has(
                                category.id.toString(),
                              )}
                              onChange={e =>
                                handleCategoryChange(
                                  category.id.toString(),
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label={category.title}
                          key={category.id}
                        />
                      ))}
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
          ),
        },
        {
          value: 'works',
          title: 'Наши работы',
          component: <ServicesWork changeBlock={changeWork} propBlock={work} />,
        },
        {
          value: 'equipment',
          title: 'Наше оборудование',
          component: (
            <ServicesEquipment
              changeBlock={changeEquipment}
              propBlock={equipment}
            />
          ),
        },
        {
          value: 'metalloobrabotka',
          title: 'Преимущества',
          component: (
            <MainAdvantages
              parentEvent={isSubmitting}
              visibilityButton={false}
              slug="metalloobrabotka"
            />
          ),
        },
        {
          value: 'faq',
          title: 'FAQ',
          component: <ServicesFaq changeBlock={changeFaq} propBlock={faq} />,
        },
        {
          value: 'seo',
          title: 'SEO',
          component: (
            <Card sx={{ p: 3 }}>
              <TextField
                {...getFieldProps('metaH1')}
                fullWidth
                type="text"
                label="Заголовок H1"
                error={Boolean(touched.metaH1 && errors.metaH1)}
                helperText={touched.metaH1 && errors.metaH1}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaTitle')}
                fullWidth
                type="text"
                label="Meta Title"
                error={Boolean(touched.metaTitle && errors.metaTitle)}
                helperText={touched.metaTitle && errors.metaTitle}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaKeywords')}
                fullWidth
                type="text"
                label="Meta Keywords"
                error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                helperText={touched.metaKeywords && errors.metaKeywords}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaDescription')}
                fullWidth
                type="text"
                multiline
                minRows={4}
                label="Meta Description"
                error={Boolean(
                  touched.metaDescription && errors.metaDescription,
                )}
                helperText={touched.metaDescription && errors.metaDescription}
              />
            </Card>
          ),
        },
      ]
    : [
        {
          value: 'general',
          title: 'Содержание',
          component: (
            <Card sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    {...getFieldProps('title')}
                    fullWidth
                    type="text"
                    label="Заголовок"
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                  {!noUrl && (
                    <>
                      <Box sx={{ mb: 2 }} />
                      <TextField
                        {...getFieldProps('staticPath')}
                        fullWidth
                        type="text"
                        label="URL услуги"
                        error={Boolean(touched.staticPath && errors.staticPath)}
                        helperText={touched.staticPath && errors.staticPath}
                      />
                    </>
                  )}

                  <Box sx={{ mb: 2 }} />
                  <FormControl fullWidth>
                    <InputLabel>Тип услуги</InputLabel>
                    <Select
                      value={values.type}
                      label="Тип услуги"
                      onChange={e => handleChangeType(e)}
                    >
                      {typeList.map(type => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={{ mb: 2 }} />
                  <TinyMCEEditor
                    value={values.content}
                    onChange={val => setContent(val)}
                  />
                  <ServicesBlocks
                    changeBlocks={changeBlocks}
                    blocksProp={values.blocks}
                  />
                  {touched.content && errors.content && (
                    <FormHelperText
                      error
                      sx={{ px: 2, textTransform: 'capitalize' }}
                    >
                      {touched.content && errors.content}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        {...getFieldProps('active')}
                        checked={values.active}
                      />
                    }
                    label={
                      <>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}
                        >
                          Активность
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  />
                  <Box sx={{ mb: 2 }} />
                  <UploadSingleFile file={file} onDrop={handleDropFile} />
                  {file && (
                    <Button color="error" onClick={handleRemoveImage}>
                      удалить
                    </Button>
                  )}
                  <Box sx={{ mb: 2 }} />
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Регионы:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedRegions.size === 0}
                          onChange={e =>
                            handleRegionChange(0, e.target.checked)
                          }
                        />
                      }
                      label="Все города"
                    />
                    {regionList.map(region => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedRegions.has(region.id)}
                            onChange={e =>
                              handleRegionChange(region.id, e.target.checked)
                            }
                          />
                        }
                        label={region.title}
                        key={region.id}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Филиалы:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedBranches.size === 0}
                          onChange={e =>
                            handleBranchChange(0, e.target.checked)
                          }
                        />
                      }
                      label="Все филиалы"
                    />
                    {contactList.map(branch => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedBranches.has(branch.id)}
                            onChange={e =>
                              handleBranchChange(branch.id, e.target.checked)
                            }
                          />
                        }
                        label={branch.title}
                        key={branch.id}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Категории:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategories.size === 0}
                          onChange={e =>
                            handleCategoryChange('0', e.target.checked)
                          }
                        />
                      }
                      label="Все категории"
                    />
                    {categoryList.map(category => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCategories.has(
                              category.id.toString(),
                            )}
                            onChange={e =>
                              handleCategoryChange(
                                category.id.toString(),
                                e.target.checked,
                              )
                            }
                          />
                        }
                        label={category.title}
                        key={category.id}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      Группы:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedGroups.size === 0}
                          onChange={e =>
                            handleGroupChange('0', e.target.checked)
                          }
                        />
                      }
                      label="Все группы"
                    />
                    {/* {grupList.map(group => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedCategories.has(group.id)}
                        onChange={e =>
                          handleGroupChange(group.id, e.target.checked)
                        }
                      />
                    }
                    label={group.title}
                  />
                ))} */}
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
          ),
        },
        {
          value: 'seo',
          title: 'SEO',
          component: (
            <Card sx={{ p: 3 }}>
              <TextField
                {...getFieldProps('metaH1')}
                fullWidth
                type="text"
                label="Заголовок H1"
                error={Boolean(touched.metaH1 && errors.metaH1)}
                helperText={touched.metaH1 && errors.metaH1}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaTitle')}
                fullWidth
                type="text"
                label="Meta Title"
                error={Boolean(touched.metaTitle && errors.metaTitle)}
                helperText={touched.metaTitle && errors.metaTitle}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaKeywords')}
                fullWidth
                type="text"
                label="Meta Keywords"
                error={Boolean(touched.metaKeywords && errors.metaKeywords)}
                helperText={touched.metaKeywords && errors.metaKeywords}
              />
              <Box sx={{ mb: 2 }} />
              <TextField
                {...getFieldProps('metaDescription')}
                fullWidth
                type="text"
                multiline
                minRows={4}
                label="Meta Description"
                error={Boolean(
                  touched.metaDescription && errors.metaDescription,
                )}
                helperText={touched.metaDescription && errors.metaDescription}
              />
            </Card>
          ),
        },
      ]

  useEffect(() => {
    const newSelectedRegions = new Set<number>()
    const newSelectedCategories = new Set<string>()
    const newSelectedGroups = new Set<string>()
    const newSelectedBranches = new Set<number>()
    if (!!currentService) {
      setFieldValue('title', currentService?.title)
      setFieldValue('type', currentService?.type)
      setFieldValue('sortOrder', currentService?.sortOrder)
      setFieldValue('staticPath', currentService?.staticPath)
      setFieldValue('active', !!currentService?.active)
      setFieldValue('content', currentService?.content)
      setFieldValue('metaH1', currentService?.metaH1)
      setFieldValue('metaTitle', currentService?.metaTitle)
      setFieldValue('metaKeywords', currentService?.metaKeywords)
      setFieldValue('metaDescription', currentService?.metaDescription)
      setFieldValue(
        'blocks',
        currentService.blocks !== null ? currentService.blocks : [],
      )
      const files = currentService?.files
      setDataReceived(true)
      if (files?.length > 0) {
        const currentFileName = files[0].file
        setFileName(currentFileName)
        setFile(`${baseUrl}/files/service/${currentFileName}`)
      } else {
        setFile(null)
        setFileName(undefined)
      }
      setContent(currentService?.content)
      currentService?.regions?.forEach(region => {
        newSelectedRegions.add(region.id)
      })
      currentService?.branches?.forEach(branch => {
        newSelectedBranches.add(branch.id)
      })
      currentService?.categories?.forEach(category => {
        newSelectedCategories.add(category.id.toString())
      })
      currentService?.groups?.forEach(group => {
        newSelectedGroups.add(group.id)
      })
    } else {
      resetForm()
      setFile(null)
      setFileName(undefined)
      setDataReceived(true)
      setContent('')
    }
    setSelectedRegions(newSelectedRegions)
    setSelectedBranches(newSelectedBranches)
    setSelectedCategories(newSelectedCategories)
    setSelectedGroups(newSelectedGroups)
  }, [currentService])

  useEffect(() => {
    if (slug) {
      getWorks()
      getFaq()
      getEquipment()
    }
  }, [slug])

  useEffect(() => {
    dispatch(getCategoryThunk())
    dispatch(getGrupThunk(''))
  }, [dispatch])

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {PAGE_TABS.map(tab => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.title}
              value={tab.value}
            />
          ))}
        </Tabs>

        {dataReceived &&
          PAGE_TABS.map(tab => {
            const isMatched = tab.value === currentTab
            return isMatched && <Box key={tab.value}>{tab.component}</Box>
          })}

        <Box sx={{ mb: 5 }} />

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Сохранить
        </LoadingButton>
        {!noUrl && (
          <Button
            color="inherit"
            variant="contained"
            sx={{ mx: 2 }}
            onClick={() => onSave(null)}
          >
            Отмена
          </Button>
        )}
      </Form>
    </FormikProvider>
  )
}
