import { createSlice } from '@reduxjs/toolkit';
import {
  FullPage,
  Page, Robots, Sitemap,
} from '../../@types/structure';

// ----------------------------------------------------------------------

type StructureState = {
  isLoading: boolean;
  error: boolean;
  pageList: Page[];
  currentPage?: FullPage;
  robots?: Robots;
  sitemap?: Sitemap;
};

const initialState: StructureState = {
  isLoading: false,
  error: false,
  pageList: [],
};

const slice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPageListSuccess(state, action) {
      state.isLoading = false;
      state.pageList = action.payload;
    },

    getPageSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload;
    },

    sortOrderUpdated(state) {
      state.isLoading= false;
    },

    getRobotsSuccess(state, action) {
      state.isLoading = false;
      state.robots = {
        text: action.payload
      };
    },

    getSitemapSuccess(state, action) {
      state.isLoading = false;
      state.sitemap = {
        text: action.payload
      };
    },
  }
});

export const { startLoading, hasError, getPageListSuccess, getPageSuccess, sortOrderUpdated, getRobotsSuccess, getSitemapSuccess } = slice.actions;

export default slice.reducer;
