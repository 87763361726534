import {
    Box, Button,
    Card, Checkbox,
    FormControlLabel, FormGroup,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {cloneDeep} from "lodash";
import {createAdsThunk, editAdsThunk} from "../../redux/thunks/ads";

type FormValuesProps = {
    title: string;
    active: boolean;
    staticPath: string;
    buttonTitle: string;
};

type Props = {
    onSave: () => void;
};

export default function AdsEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentAdsItem } = useSelector((state: RootState) => state.ads);
    const { regionList } = useSelector((state: RootState) => state.contact);

    const isEdit = currentAdsItem !== undefined;

    const [selectedRegions, setSelectedRegions] = useState<Set<number>>(new Set());

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            active: true,
            staticPath: '',
            buttonTitle: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const params = {
                ...values,
                active: values.active ? 1 : 0,
                regions: Array.from(selectedRegions),
            }
            const result = isEdit
                ? await dispatch(editAdsThunk(''+currentAdsItem?.id, params))
                : await dispatch(createAdsThunk(params));

            setSubmitting(false);
            if(result) {
                resetForm();
                enqueueSnackbar('Объявление сохранено', {variant: 'success'});
                onSave();
            }
            else {
                enqueueSnackbar("Ошибка сохранения объявления", { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        const newSelectedRegions = new Set<number>();
        if(currentAdsItem){
            setFieldValue('title', currentAdsItem?.title);
            setFieldValue('active', !!currentAdsItem?.active);
            setFieldValue('staticPath', currentAdsItem?.staticPath);
            setFieldValue('buttonTitle', currentAdsItem?.buttonTitle);
            currentAdsItem?.regions?.forEach(region => {
                newSelectedRegions.add(region.id);
            });
        }
        else {
            resetForm();
        }
        setSelectedRegions(newSelectedRegions);
    }, [currentAdsItem]);

    const handleRegionChange = (regionId: number, checked: boolean) => {
        const newSelectedRegions = cloneDeep(selectedRegions);
        if(regionId === 0) {
            if(checked) {
                newSelectedRegions.clear();
            }
        }
        else {
            if(checked) {
                newSelectedRegions.add(regionId);
            }
            else {
                newSelectedRegions.delete(regionId);
            }
        }

        setSelectedRegions(newSelectedRegions);
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('buttonTitle')}
                                fullWidth
                                type="text"
                                label="Название кнопки"
                                error={Boolean(touched.buttonTitle && errors.buttonTitle)}
                                helperText={touched.buttonTitle && errors.buttonTitle}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="Ссылка"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                            <FormGroup>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Регионы:
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedRegions.size === 0}
                                            onChange={e => handleRegionChange(0, e.target.checked)}
                                        />
                                    }
                                    label="Все города"
                                />
                                {regionList.map(region => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedRegions.has(region.id)}
                                                onChange={e => handleRegionChange(region.id, e.target.checked)}
                                            />
                                        }
                                        label={region.title}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Card>

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
}