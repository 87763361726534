import {AppThunk} from "../store";
import axios from "../../utils/axios";
import { startLoading, hasError, getAnalyticsListSuccess, getAnalyticsItemSuccess, changeAnalyticsItemActiveStatus, deleteAnalyticsItem } from "redux/slices/analytics";

export const getAnalyticsListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axios.get('/analytics');
        dispatch(getAnalyticsListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getAnalyticsItemThunk = (
    id: number
):AppThunk => async dispatch => {
    if(id === 0){
        dispatch(getAnalyticsItemSuccess(undefined));
    }
    else {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/analytics/${id}`);
            dispatch(getAnalyticsItemSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export const createAnalyticsItemThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.post(`/analytics`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editAnalyticsItemThunk = (
    itemId: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axios.put(`/analytics/${itemId}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const deleteAnalyticsItemThunk = (
    itemId: string
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axios.delete(`/analytics/${itemId}`);
        if(response.data.result) {
            dispatch(deleteAnalyticsItem(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const changeActiveAnalyticsItemThunk = (
    itemId: string,
    active: boolean,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            active: active ? 1 : 0,
        }
        const response = await axios.post(`/analytics/${itemId}/activate`, params);
        if(response.data.result){
            dispatch(changeAnalyticsItemActiveStatus(itemId));
            return true;
        }
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}
