import { createSlice } from '@reduxjs/toolkit'

// ----------------------------------------------------------------------

export interface IFilter {
  name: string
  key: string
  value: { id: number; value: string }[]
}

type TagState = {
  isLoading: boolean
  error: boolean
  filters: IFilter[]
}

const initialState: TagState = {
  isLoading: false,
  error: false,
  filters: [],
}

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getFilters(state, action) {
      state.isLoading = false
      state.filters = action.payload
    },
  },
})

export const { startLoading, hasError, getFilters } = slice.actions

export default slice.reducer
