export enum Roles {
  admin = 'admin',
  marketer = 'marketer',
  announcement = 'announcement',
  hr = 'hr',
  support = 'support',
}



// {label: "Администратор", code: "admin"} 
// {label: "Маркетолог", code: "marketer"}
// {label: "Аннонсер", code: "announcement"}
// {label: "Рекрутеры", code: "hr"}
// {label: "Специалист поддержки", code: "support"}
// {label: "Клиент", code: "client"}