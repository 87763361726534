import React, { useState, useEffect } from 'react';
// material
import {
    Link,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination, TableHead, Switch, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog
} from '@mui/material';
import Moment from 'moment';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';

import {Link as RouterLink} from "react-router-dom";
import Scrollbar from '../../components/Scrollbar';
import DeleteIcon from "@mui/icons-material/Delete";
import {PATH_DASHBOARD} from "../../routes/paths";
import styles from "./PublcationsTable.module.css";
import {useSnackbar} from "notistack";
import {PublicationsItem} from "../../@types/publications";
import {
    changeActivePublicationsItemThunk,
    deletePublicationsItemThunk,
    getPublicationsListThunk
} from "../../redux/thunks/publications";

export default function PublicationsTable() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { publicationsList } = useSelector((state: RootState) => state.publications);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<PublicationsItem | undefined>(undefined);

    useEffect(() => {
        dispatch(getPublicationsListThunk());
    }, [dispatch]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 20));
        setPage(0);
    };

    const handleDelete = (item: PublicationsItem) => {
        setOpenDeleteConfirm(item);
    }

    const handleDeleteClose = async (confirm: boolean) => {
        const id = openDeleteConfirm?.id;
        setOpenDeleteConfirm(undefined);
        if(confirm){
            const result = await dispatch(deletePublicationsItemThunk(''+id));
            if(result){
                enqueueSnackbar('Публикация удалена', {variant: 'success'});
            }
            else {
                enqueueSnackbar("Ошибка удаления публикации", { variant: 'error' });
            }
        }
    }

    const handleActiveChange = async (item: PublicationsItem, checked: boolean) => {
        const result = await dispatch(changeActivePublicationsItemThunk(''+item.id, checked));
        if(result) {
            enqueueSnackbar('Активность публикации изменена', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на изменение активности публикации", { variant: 'error' });
        }
    };

    return (
        <>
            <Dialog open={openDeleteConfirm !== undefined} onClose={() => handleDeleteClose(false)}>
                <DialogTitle>Удалить публикацию?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы действительно хотите удалить публикацию "{openDeleteConfirm?.title}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteClose(false)} autoFocus>Отмена</Button>
                    <Button onClick={() => handleDeleteClose(true)}>Подтвердить</Button>
                </DialogActions>
            </Dialog>

            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Заголовок
                                    </TableCell>
                                    <TableCell>
                                        Дата
                                    </TableCell>
                                    <TableCell align="right">
                                        Активность
                                    </TableCell>
                                    <TableCell>
                                        &nbsp;
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {publicationsList
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const { id, title, date, active } = row;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Link underline="none" className={`${styles.labelLink}`} component={RouterLink} to={`${PATH_DASHBOARD.publications.root}/${id}/edit`}>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {title}
                                                            </Typography>
                                                        </Link>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{Moment(date).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell align="right">
                                                    <Switch checked={!!active} onChange={(e, checked) => handleActiveChange(row, checked)} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div onClick={(e) => handleDelete(row)}>
                                                        <DeleteIcon sx={{cursor: 'pointer'}}/>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={publicationsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
}
