import { createSlice } from '@reduxjs/toolkit'
import { IMeta } from '../../../@types/meta'
import { ISuccessStory } from '../../../@types/hr/successStory'

type StructureState = {
  isLoading: boolean
  error: boolean
  successStoryList: {
    data: ISuccessStory[]
    meta: IMeta
  }
  currentSuccessStory?: ISuccessStory
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  successStoryList: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
}

const slice = createSlice({
  name: 'hrSuccessStory',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrSuccessStoryListSuccess(state, action) {
      state.isLoading = false
      state.successStoryList = action.payload
    },

    getHrSuccessStorySuccess(state, action) {
      state.isLoading = false
      state.currentSuccessStory = action.payload
    },

    changeSuccessStoryActiveStatus(state, action) {
      const list = state.successStoryList.data.map(item => {
        const is_active =
          item.id !== action.payload
            ? item.is_active
            : item.is_active
            ? false
            : true

        return {
          ...item,
          is_active,
        }
      })
      state.successStoryList.data = list
    },
    deleteSuccessStoryStatus(state, action) {
      const list = state.successStoryList.data.filter(
        i => Number(i.id) !== Number(action.payload),
      )
      state.successStoryList.data = list
    },
  },
})

export const {
  startLoading,
  hasError,
  changeSuccessStoryActiveStatus,
  deleteSuccessStoryStatus,
  getHrSuccessStoryListSuccess,
  getHrSuccessStorySuccess,
} = slice.actions

export default slice.reducer
