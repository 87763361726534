import axios from "axios";

// ----------------------------------------------------------------------

export const baseUrl = process.env.REACT_APP_BASE_URL || "/api/v1";
// export const baseUrl = 'https://back.brokinvest.ru/api/v1';
// export const baseUrl = "https://brok.st.atwinta.site/api/v1";

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
