import { createSlice } from '@reduxjs/toolkit'
import { IMeta } from '../../@types/meta'
import { landingsType } from '../../@types/landings'

// ----------------------------------------------------------------------

type TagState = {
  isLoading: boolean
  error: boolean
  meta: IMeta
  landingsList: landingsType[]
  currentlandingsItem?: landingsType
}

const initialState: TagState = {
  isLoading: false,
  error: false,
  meta: {
    current_page: 1,
    from: 0,
    last_page: 1,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  landingsList: [],
}

const slice = createSlice({
  name: 'landings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getlandingsListSuccess(state, action) {
      state.isLoading = false
      state.landingsList = action.payload
    },

    getCurrentlandingsItemSuccess(state, action) {
      state.isLoading = false
      state.currentlandingsItem = action.payload
    },

    deletelandingsItem(state, action) {
      const list = state.landingsList.filter(
        item => item.slug !== action.payload,
      )
      state.landingsList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getlandingsListSuccess,
  deletelandingsItem,
  getCurrentlandingsItemSuccess
} = slice.actions

export default slice.reducer
