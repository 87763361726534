import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getPublicationsItemThunk} from "../../../redux/thunks/publications";
import PublicationsEdit from "../../../components/publications/PublicationsEdit";
// ----------------------------------------------------------------------

export default function PublicationsCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, currentPublicationsItem } = useSelector((state: RootState) => state.publications);

    const { id = 0 } = useParams();
    const isEdit = currentPublicationsItem !== undefined;

    useEffect(() => {
        dispatch(getPublicationsItemThunk(+id));
    }, [dispatch]);

    const handleSave = () => {
        navigate(PATH_DASHBOARD.publications.root);
    }

    return (
        <DataLoadingPage
            loading={isLoading}
            title='Редактирование публикации'
            heading={!isEdit ? 'Создание публикации' : 'Редактирование публикации'}
            links={[
                { name: 'Главная', href: PATH_DASHBOARD.root },
                { name: 'Публикации', href: PATH_DASHBOARD.publications.root },
                { name: !isEdit ? 'Новая публикация' : (currentPublicationsItem?.title || '') }
            ]}
        >

            <PublicationsEdit
                onSave={handleSave}
            />

        </DataLoadingPage>
    );
}
