// material
import {
    Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import PublicationsTable from "../../../components/publications/PublicationsTable";

export default function PublicationsList() {
    const { themeStretch } = useSettings();

    return (
        <Page title="Публикации в СМИ">
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading="Публикации в СМИ"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        { name: 'Публикации в СМИ'},
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={`${PATH_DASHBOARD.publications.create}`}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Публикация
                        </Button>
                    }
                />

                <PublicationsTable />

            </Container>
        </Page>
    );
}
