// material
import { Container, Box } from '@mui/material'
// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
// hooks
import useSettings from '../../../hooks/useSettings'
import Page from '../../../components/Page'
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs'

import LogsTable from 'components/logs/LogsTable'

export default function LogsList() {
  const { themeStretch } = useSettings()

  return (
    <Page title="Список логов">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Список логов"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Логи' },
          ]}
        />

        <Box sx={{ mb: 5 }} />
        <LogsTable />
      </Container>
    </Page>
  )
}
