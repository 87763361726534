import { useState, useEffect } from 'react'
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { RootState, useDispatch, useSelector } from '../../redux/store'
import { getLoggableTypeThunk, getLogsListThunk } from 'redux/thunks/logs'
import Scrollbar from 'components/Scrollbar'
import { TableControl } from 'components/TableControl'
import { ILogs } from 'redux/slices/logs'
import { Box } from '@mui/material'
import s from './LogsTable.module.css'

export default function LogsTable() {
  const dispatch = useDispatch()

  const { logsList, meta, loggableType } = useSelector(
    (state: RootState) => state.logs,
  )

  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState('desc')
  const [whereDefCat, setWhereDefCat] = useState<string>('0')

  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(event.target.value as string)
    setPage(1)
  }

  const TABLE_HEAD = [
    { id: 'id', label: 'ID' },
    { id: 'date', label: 'Дата' },
    { id: 'user', label: 'Пользователь' },
    { id: 'action', label: 'Действие' },
    { id: 'where', label: 'Где' },
    { id: 'actionMake', label: 'Что изменено' },
  ]

  useEffect(() => {
    dispatch(
      getLogsListThunk(
        page,
        rowsPerPage,
        whereDefCat === '0'
          ? ''
          : whereDefCat.split('\\')[whereDefCat.split('\\').length - 1],
        sort,
      ),
    )
  }, [dispatch, page, rowsPerPage, sort, whereDefCat])

  useEffect(() => {
    dispatch(getLoggableTypeThunk())
  }, [dispatch])

  const handleAction = (str: string): string => {
    switch (str) {
      case 'updated':
        return 'обновлено'
      case 'created':
        return 'создано'
      case 'deleted':
        return 'удалено'

      default:
        return ''
    }
  }

  const findDifferences = (obj1: any, obj2: any) => {
    const differences: any = {}

    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        differences[key] = {
          oldValue: obj1[key],
          newValue: obj2[key],
        }
      }
    }

    return differences
  }

  const different = (log: ILogs) => {
    if (log.action === 'created' || log.action === 'deleted') {
      return (
        <div className={s.actions}>
          <span style={{ color: 'green' }}>Было:</span>
          <div className={s.actions__info}>
            {Object.entries(log.model_before).map(i => {
              if (i[0] === 'updated_at' || i[0] === 'created_at') {
                return ''
              }

              return (
                <>
                  <span>{`${i[0]} = ${i[1]}`}</span>
                </>
              )
            })}
          </div>
          <span style={{ color: 'red' }}>Стало:</span>
          <div className={s.actions__info}>
            {Object.entries(log.model_after).map(i => {
                 if (i[0] === 'updated_at' || i[0] === 'created_at') {
                  return ''
                }
              return (
                <>
                  <span>{`${i[0]} = ${i[1]}`}</span>
                </>
              )
            })}
          </div>
        </div>
      )
    } else {
      const resultDifferences = findDifferences(
        log.model_before,
        log.model_after,
      )
      delete resultDifferences.updated_at

      return (
        <div className={s.actions}>
          <div className={s.actions__info}>
            {Object.keys(resultDifferences).map(i => {
              return (
                <>
                  <span>Ключ: {i}</span>
                  <div className={s.val}>
                    <div className={s.val__cont}>
                      <span style={{ color: 'green' }}>Было:</span>
                      <span>{String(resultDifferences[i].oldValue)}</span>
                    </div>
                    <div className={s.val__cont}>
                      <span style={{ color: 'red' }}>Стало:</span>
                      <span>{String(resultDifferences[i].newValue)}</span>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      )
    }
  }

  const whereDifferentt = (log: ILogs) => {
    const findWhere = loggableType.find(i => i.value === log.loggable_type)

    return findWhere?.name
  }

  const handleChangeCategory = (e: SelectChangeEvent<string>) => {
    setWhereDefCat(e.target.value)
  }

  return (
    <>
      <Card sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Сортировка по времени изменения</InputLabel>
              <Select
                value={sort}
                label="Сортировка по времени изменения"
                onChange={e => setSort(e.target.value)}
              >
                <MenuItem value={'desc'}>Сначала новые</MenuItem>
                <MenuItem value={'asc'}>Сначала старые</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Категория</InputLabel>
              <Select
                value={whereDefCat}
                label="Категория"
                onChange={e => handleChangeCategory(e)}
              >
                <MenuItem value={'0'}>Все</MenuItem>
                {loggableType.map(i => (
                  <MenuItem value={i.value}>{i.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {logsList.length ? (
          <>
            <Box sx={{ mb: 2 }} />
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map(headCell => (
                        <TableCell key={headCell.id} align="center">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logsList.map(log => {
                      return (
                        <TableRow
                          hover
                          key={log.id}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell align="center">
                            {/* <Link
                                      underline="none"
                                      style={{ color: 'black' }}
                                      component={RouterLink}
                                      to={`${PATH_DASHBOARD.catalog.root}/item/${id}/edit`}
                                    > */}
                            {log.id}
                            {/* </Link> */}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(log.created_at).toLocaleString('ru-RU')}
                          </TableCell>

                          <TableCell align="center">{log.user.name}</TableCell>
                          <TableCell align="center">
                            {handleAction(log.action)}
                          </TableCell>
                          <TableCell align="center">
                            {whereDifferentt(log)}
                          </TableCell>
                          <TableCell align="left">{different(log)}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TableControl
              meta={meta}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              handleChange={handleChange}
            />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  )
}
