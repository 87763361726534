import {
    Box, Button,
    Card,
    FormControlLabel, Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {createSpecialityThunk, editSpecialityThunk} from "../../redux/thunks/vacancies";

type FormValuesProps = {
    title: string;
    active: boolean;
};

type Props = {
    onSave: () => void;
};

export default function SpecialityEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentSpeciality } = useSelector((state: RootState) => state.vacancies);

    const isEdit = currentSpeciality !== undefined;

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите название'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            active: true,
            },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const params = {
                ...values,
                active: values.active ? 1 : 0,
            }
            const result = isEdit
                ? await dispatch(editSpecialityThunk(''+currentSpeciality?.id, params))
                : await dispatch(createSpecialityThunk(params));

            setSubmitting(false);
            if(result) {
                resetForm();
                enqueueSnackbar('Специализация сохранена', {variant: 'success'});
                onSave();
            }
            else {
                enqueueSnackbar("Ошибка сохранения специализации", { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        if(currentSpeciality){
            setFieldValue('title', currentSpeciality?.title);
            setFieldValue('active', !!currentSpeciality?.active);
        }
        else {
            resetForm();
        }
    }, [currentSpeciality]);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                        </Grid>
                     </Grid>
                </Card>

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
}