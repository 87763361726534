import { AppThunk } from '../store'
import axios from '../../utils/axios'
import {
  startLoading,
  hasError,
  getLogsListSuccess,
  getLoggableTypeSuccess,
} from 'redux/slices/logs'

export const getLogsListThunk =
  (
    page: number,
    perPage: string,
    whereDefCat: string,
    sort: string,
  ): AppThunk =>
  async dispatch => {
    dispatch(startLoading())
    try {
      const response = await axios.get(
        `/logs?page=${page}&per_page=${perPage}&type=${whereDefCat}&sort=${sort}`,
      )
      dispatch(getLogsListSuccess(response.data))
    } catch (error) {
      dispatch(hasError(error))
    }
  }
export const getLoggableTypeThunk = (): AppThunk => async dispatch => {
  dispatch(startLoading())
  try {
    const response = await axios.get(`/logs/types`)
    dispatch(getLoggableTypeSuccess(response))
  } catch (error) {
    dispatch(hasError(error))
  }
}
