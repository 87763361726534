import React, { useState, useEffect } from 'react';
// material
import {
    Link,
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination, TableHead, Switch, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';

import {Link as RouterLink} from "react-router-dom";
import Scrollbar from '../../components/Scrollbar';
import DeleteIcon from "@mui/icons-material/Delete";
import {PATH_DASHBOARD} from "../../routes/paths";
import styles from "./AdsTable.module.css";
import {useSnackbar} from "notistack";
import {AdsItem} from "../../@types/ads";
import {changeActiveAdsThunk, deleteAdsThunk, getAdsListThunk} from "../../redux/thunks/ads";

export default function AdsTable() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { adsList } = useSelector((state: RootState) => state.ads);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<AdsItem | undefined>(undefined);

    useEffect(() => {
        dispatch(getAdsListThunk());
    }, [dispatch]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 20));
        setPage(0);
    };

    const handleDelete = (item: AdsItem) => {
        setOpenDeleteConfirm(item);
    }

    const handleDeleteClose = async (confirm: boolean) => {
        const id = openDeleteConfirm?.id || 0;
        setOpenDeleteConfirm(undefined);
        if(confirm){
            const result = await dispatch(deleteAdsThunk(id));
            if(result){
                enqueueSnackbar('Объявление удалено', {variant: 'success'});
            }
            else {
                enqueueSnackbar("Ошибка удаления объявления", { variant: 'error' });
            }
        }
    }

    const handleActiveChange = async (item: AdsItem, checked: boolean) => {
        const result = await dispatch(changeActiveAdsThunk(item.id, checked));
        if(result) {
            enqueueSnackbar('Активность объявления изменена', {variant: 'success'});
        }
        else {
            enqueueSnackbar("Ошибка выполнения запроса на изменение активности объявления", { variant: 'error' });
        }
    };

    return (
        <>
            <Dialog open={openDeleteConfirm !== undefined} onClose={() => handleDeleteClose(false)}>
                <DialogTitle>Удалить объявление?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Вы действительно хотите удалить объявление "{openDeleteConfirm?.title}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteClose(false)} autoFocus>Отмена</Button>
                    <Button onClick={() => handleDeleteClose(true)}>Подтвердить</Button>
                </DialogActions>
            </Dialog>

            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Заголовок
                                    </TableCell>
                                    <TableCell>
                                        Город
                                    </TableCell>
                                    <TableCell align="right">
                                        Активность
                                    </TableCell>
                                    <TableCell>
                                        &nbsp;
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {adsList
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const { id, title, active, regions } = row;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Link underline="none" className={`${styles.labelLink}`} component={RouterLink} to={`${PATH_DASHBOARD.ads.root}/${id}/edit`}>
                                                            <Typography variant="subtitle2" noWrap>
                                                                {title}
                                                            </Typography>
                                                        </Link>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {regions.length === 0 ? 'Все' : regions.map(region => (region.title)).join(', ')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Switch checked={!!active} onChange={(e, checked) => handleActiveChange(row, checked)} />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div onClick={(e) => handleDelete(row)}>
                                                        <DeleteIcon sx={{cursor: 'pointer'}}/>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={adsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
}
