import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getArticlesItemThunk} from "../../../redux/thunks/articles";
import ArticlesEdit from "../../../components/articles/ArticlesEdit";
// ----------------------------------------------------------------------

export default function ArticlesCreate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, currentArticlesItem } = useSelector((state: RootState) => state.articles);

    const { id = '' } = useParams();
    const isEdit = currentArticlesItem !== undefined;
    

    useEffect(() => {
        dispatch(getArticlesItemThunk(id));
    }, [dispatch]);

    const handleSave = () => {
        navigate(PATH_DASHBOARD.articles.root);
    }

    return (
        <DataLoadingPage
            loading={isLoading}
            title='Редактирование статьи'
            heading={!isEdit ? 'Создание статьи' : 'Редактирование статьи'}
            links={[
                { name: 'Главная', href: PATH_DASHBOARD.root },
                { name: 'Cтатьи', href: PATH_DASHBOARD.articles.root },
                { name: !isEdit ? 'Новая статья' : (currentArticlesItem?.title || '') }
            ]}
        >

            <ArticlesEdit
                onSave={handleSave}
            />

        </DataLoadingPage>
    );
}
