import { createSlice } from '@reduxjs/toolkit'
import { IMeta } from '../../@types/meta'
import { IAction } from '../../@types/pagination'

// ----------------------------------------------------------------------

interface User {
  id: number
  firstName: string
  lastName: string
  surname: string
  name: string
  email: string
  phone?: any
  role: string
  position: string
  active: number
  createdAt: string
  updatedAt: string
  is_banned: number
  companies: any[]
}

interface Request_data {
  id?: any
  title: string
  category_id?: string
  group_id?: number
  stock_id?: string
  active: boolean
  metaH1?: any
  metaTitle?: any
  metaKeywords?: any
  metaDescription?: any
  slug?: any
  items: number[]
}

export interface ILogs {
  id: number
  loggable_type: string
  loggable_id: number
  user: User
  action: string
  model_before: any
  model_after: any
  request_data: Request_data
  created_at: string
  updated_at: string
}

export interface ILoggableType {
  name: string
  value: string
}

type LogsState = {
  isLoading: boolean
  error: boolean
  meta: IMeta
  logsList: ILogs[]
  logItem?: ILogs
  loggableType: ILoggableType[]
}

const initialState: LogsState = {
  isLoading: false,
  error: false,
  meta: {
    current_page: 1,
    from: 0,
    last_page: 1,
    path: '',
    per_page: 0,
    to: 0,
    total: 0,
  },
  logsList: [],
  loggableType: [],
}

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getLogsListSuccess(state, action: IAction<ILogs>) {
      state.logsList = action.payload.data
      state.meta = action.payload.meta
      state.isLoading = false
    },
    getLoggableTypeSuccess(state, action) {
      state.loggableType = action.payload.data
      state.isLoading = false
    },
  },
})

export const {
  startLoading,
  hasError,
  getLogsListSuccess,
  getLoggableTypeSuccess,
} = slice.actions

export default slice.reducer
