// material
import {
    Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import AnalyticsTable from "../../../components/analytics/AnalyticsTable";

export default function AnalyticsList() {
    const { themeStretch } = useSettings();

    return (
        <Page title="Аналитические статьи">
            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading="Аналитические статьи"
                    links={[
                        { name: 'Главная', href: PATH_DASHBOARD.root },
                        { name: 'Аналитические статьи'},
                    ]}
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={`${PATH_DASHBOARD.analytics.create}`}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Статья
                        </Button>
                    }
                />

                <AnalyticsTable />

            </Container>
        </Page>
    );
}
