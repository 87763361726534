import {ReactNode} from "react";
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";
import useSettings from "../hooks/useSettings";
import Page from "./Page";
import {Container} from "@mui/material";

type Link = {
    href?: string;
    name: string;
};

type DataLoadingPageProps = {
    children: ReactNode;
    loading: boolean;
    title: string;
    heading: string;
    links: Link[];
};

export default function DataLoadingPage({ children, loading, title, heading, links }: DataLoadingPageProps) {
    const { themeStretch } = useSettings();

    return (
        <>
            {loading ? (
                <div>Загрузка...</div>
            ) : (
                <Page title={title}>
                    <Container maxWidth={themeStretch ? false : 'lg'}>
                        <HeaderBreadcrumbs
                            heading={heading}
                            links={links}
                        />
                        {children}
                    </Container>
                </Page>
            )}
        </>
    );
}