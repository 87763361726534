import {
    Box, Button,
    Card, Checkbox,
    FormControlLabel, FormGroup,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState, useCallback} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {uploadFileThunk} from "../../redux/thunks/files";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {UploadSingleFile} from "../upload";
import {baseUrl} from "../../utils/axios";
import {createBannerThunk, editBannerThunk} from "../../redux/thunks/banners";
import {cloneDeep} from "lodash";

type FormValuesProps = {
    title: string;
    content: string;
    sortOrder: number;
    active: boolean;
    staticPath: string;
    buttonTitle: string;
};

type Props = {
    onSave: () => void;
};

export default function BannersEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentBannersItem } = useSelector((state: RootState) => state.banners);
    const { regionList } = useSelector((state: RootState) => state.contact);

    const isEdit = currentBannersItem !== undefined;

    const [file, setFile] = useState<File | string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [selectedRegions, setSelectedRegions] = useState<Set<number>>(new Set());

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            content: '',
            active: true,
            sortOrder: 0,
            staticPath: '',
            buttonTitle: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const params = {
                ...values,
                active: values.active ? 1 : 0,
                files: fileName !== undefined ? [fileName] : [],
                regions: Array.from(selectedRegions),
            }
            const result = isEdit
                ? await dispatch(editBannerThunk(''+currentBannersItem?.id, params))
                : await dispatch(createBannerThunk(params));

            setSubmitting(false);
            if(result) {
                resetForm();
                enqueueSnackbar('Баннер сохранен', {variant: 'success'});
                onSave();
            }
            else {
                enqueueSnackbar("Ошибка сохранения баннера", { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        const newSelectedRegions = new Set<number>();
        if(currentBannersItem){
            setFieldValue('title', currentBannersItem?.title);
            setFieldValue('content', currentBannersItem?.content);
            setFieldValue('sortOrder', currentBannersItem?.sortOrder);
            setFieldValue('active', !!currentBannersItem?.active);
            setFieldValue('staticPath', currentBannersItem?.staticPath);
            setFieldValue('buttonTitle', currentBannersItem?.buttonTitle);
            const files = currentBannersItem?.files;
            if(files?.length > 0){
                const currentFileName = files[0].file;
                setFileName(currentFileName);
                setFile(`${baseUrl}/files/banner/${currentFileName}`);
            }
            else {
                setFile(null);
                setFileName(undefined);
            }
            currentBannersItem?.regions?.forEach(region => {
                newSelectedRegions.add(region.id);
            });
        }
        else {
            resetForm();
            setFile(null);
            setFileName(undefined);
        }
        setSelectedRegions(newSelectedRegions);
    }, [currentBannersItem]);

    const handleDropFile = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const loadedFileName = await dispatch(uploadFileThunk(file));
            if(loadedFileName){
                setFileName(loadedFileName);
                setFile({
                    ...file,
                    preview: URL.createObjectURL(file)
                });
            }
            else {
                enqueueSnackbar("Ошибка загрузки файла", { variant: 'error' });
            }
        }
    }, []);

    const handleRemoveImage = useCallback(() => {
        setFile(null);
        setFileName(undefined);
    }, []);

    const handleRegionChange = (regionId: number, checked: boolean) => {
        const newSelectedRegions = cloneDeep(selectedRegions);
        if(regionId === 0) {
            if(checked) {
                newSelectedRegions.clear();
            }
        }
        else {
            if(checked) {
                newSelectedRegions.add(regionId);
            }
            else {
                newSelectedRegions.delete(regionId);
            }
        }

        setSelectedRegions(newSelectedRegions);
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('content')}
                                fullWidth
                                multiline
                                minRows={4}
                                type="text"
                                label="Описание"
                                error={Boolean(touched.content && errors.content)}
                                helperText={touched.content && errors.content}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('buttonTitle')}
                                fullWidth
                                type="text"
                                label="Название кнопки"
                                error={Boolean(touched.buttonTitle && errors.buttonTitle)}
                                helperText={touched.buttonTitle && errors.buttonTitle}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="Ссылка"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('sortOrder')}
                                fullWidth
                                type="number"
                                label="Порядок"
                                error={Boolean(touched.sortOrder && errors.sortOrder)}
                                helperText={touched.sortOrder && errors.sortOrder}
                            />
                            <Box sx={{ mb: 2 }} />
                            <UploadSingleFile file={file} onDrop={handleDropFile} />
                            {file && (
                                <Button color="error" onClick={handleRemoveImage}>удалить</Button>
                            )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                            <FormGroup>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Регионы:
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedRegions.size === 0}
                                            onChange={e => handleRegionChange(0, e.target.checked)}
                                        />
                                    }
                                    label="Все города"
                                />
                                {regionList.map(region => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedRegions.has(region.id)}
                                                onChange={e => handleRegionChange(region.id, e.target.checked)}
                                            />
                                        }
                                        label={region.title}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Card>

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
}