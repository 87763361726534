import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getRegionListThunk} from "../../../redux/thunks/contact";
import {getSpecialityListThunk, getVacanciesItemThunk} from "../../../redux/thunks/vacancies";
import VacanciesEdit from "../../../components/vacancies/VacanciesEdit";

// ----------------------------------------------------------------------

export default function VacanciesCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, currentVacanciesItem } = useSelector((state: RootState) => state.vacancies);

  const { id = 0 } = useParams();
  const isEdit = currentVacanciesItem !== undefined;

  useEffect(() => {
      dispatch(getSpecialityListThunk());
      dispatch(getVacanciesItemThunk(+id));
      dispatch(getRegionListThunk());
  }, [dispatch]);

  const handleSave = () => {
    navigate(PATH_DASHBOARD.vacancies.root);
  }

  return (
      <DataLoadingPage
        loading={isLoading}
        title='Редактирование вакансии'
        heading={!isEdit ? 'Создание вакансии' : 'Редактирование вакансии'}
        links={[
          { name: 'Главная', href: PATH_DASHBOARD.root },
          { name: 'Вакансии', href: PATH_DASHBOARD.vacancies.root },
          { name: !isEdit ? 'Новая вакансия' : (currentVacanciesItem?.title || '') }
        ]}
      >
        <VacanciesEdit
            onSave={handleSave}
        />
      </DataLoadingPage>
  );
}
