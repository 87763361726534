import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import {
  UserManager,
} from '../../@types/user';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  userList: UserManager[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userList: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
  }
});

export const { startLoading, hasError, deleteUser, getUserListSuccess } = slice.actions;

export default slice.reducer;


