import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import { forwardRef, useEffect, useCallback, ReactNode } from 'react';
// material
import { Box, BoxProps } from '@mui/material';
// utils
import track from '../utils/analytics';
import ability from '../ability/ability';
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  const checkAbility = () => {
    let accessPath = pathname;
    const path = accessPath.split('/');
    if(path[path.length -1] === 'edit') {
      accessPath = path.slice(0, path.length - 2).join('/') + '/new'; // check new path access for edit link
    }
    return ability(user).can('view', accessPath);
  }

  return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {checkAbility() ? (children) : ("Доступ запрещен")}
      </Box>
  );
});

export default Page;
