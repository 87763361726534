import { createSlice } from '@reduxjs/toolkit'
import { IHrEvent } from '../../../@types/hr/event'

type StructureState = {
  isLoading: boolean
  error: boolean
  eventList: IHrEvent[]
  currentEvent?: IHrEvent
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  eventList: [],
}

const slice = createSlice({
  name: 'hrEvent',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrEventListSuccess(state, action) {
      state.isLoading = false
      state.eventList = action.payload
    },

    getHrEventSuccess(state, action) {
      state.isLoading = false
      state.currentEvent = action.payload
    },

    changeEventActiveStatus(state, action) {
      const list = state.eventList.map(item => {
        const is_active =
          item.static_path !== action.payload
            ? item.is_active
            : item.is_active
            ? false
            : true

        return {
          ...item,
          is_active,
        }
      })
      state.eventList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getHrEventListSuccess,
  getHrEventSuccess,
  changeEventActiveStatus,
} = slice.actions

export default slice.reducer
