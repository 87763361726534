import { createSlice } from '@reduxjs/toolkit'
import { IHrVacancies, IHrVacancy } from '../../../@types/hr/vacancy'
import { IMeta } from '../../../@types/meta'

type StructureState = {
  isLoading: boolean
  error: boolean
  vacanciesList: {
    data: IHrVacancies[]
    meta: IMeta
  }
  currentVacancy?: IHrVacancy
}

const initialState: StructureState = {
  isLoading: false,
  error: false,
  vacanciesList: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
}

const slice = createSlice({
  name: 'hrVacancy',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },

    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getHrVacancyListSuccess(state, action) {
      state.isLoading = false
      state.vacanciesList = action.payload
    },

    getHrVacancySuccess(state, action) {
      state.isLoading = false
      state.currentVacancy = action.payload
    },

    changeVacancyActiveStatus(state, action) {
      const list = state.vacanciesList.data.map(item => {
        const is_active =
          item.static_path !== action.payload
            ? item.is_active
            : item.is_active
            ? false
            : true

        return {
          ...item,
          is_active,
        }
      })
      state.vacanciesList.data = list
    },
    deleteVacancyStatus(state, action) {
      const list = state.vacanciesList.data.filter(
        i => i.static_path !== action.payload,
      )
      state.vacanciesList.data = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getHrVacancyListSuccess,
  deleteVacancyStatus,
  changeVacancyActiveStatus,
  getHrVacancySuccess,
} = slice.actions

export default slice.reducer
