import { AppThunk } from '../store';
import axios from '../../utils/axios';
import { PostFileResponse, PostFilesResponse } from '../../@types/files';

export const uploadFileThunk =
  (file: File): AppThunk<Promise<string | void>> =>
  async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append(`files[]`, file);

      const {
        data: { filenames },
      } = await axios.post<PostFileResponse>(`/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return filenames[0];
    } catch (error) {}
  };

export const uploadFilesThunk =
  (files: FileList): AppThunk<Promise<string[] | void>> =>
  async (dispatch) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append(`files[]`, files[i]);
      }

      const {
        data: { filenames },
      } = await axios.post<PostFilesResponse>(`/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return filenames;
    } catch (error) {}
  };
