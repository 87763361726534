import { Ability, AbilityBuilder } from '@casl/ability'
import { AuthUser } from '../@types/authentication'
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths'
import { Roles } from './roles'

export default function defineAbilityFor(user?: AuthUser) {
  const { can, rules } = new AbilityBuilder(Ability)
  const role = user?.role

  if (role === Roles.admin) {
    can(['view', 'delete'], 'all')
  }
  if (role === Roles.hr) {
    can(['view', 'delete'], 'all')
  }
  if (role === Roles.marketer) {
    can('view', [
      PATH_DASHBOARD.articles.root,
      PATH_DASHBOARD.articles.create,
      PATH_DASHBOARD.publications.root,
      PATH_DASHBOARD.publications.create,
      PATH_DASHBOARD.news.root,
      PATH_DASHBOARD.news.createCategory,
      PATH_DASHBOARD.news.create,
      PATH_DASHBOARD.hr.event.root,
      PATH_DASHBOARD.hr.event.newEvent,
      PATH_DASHBOARD.hr.article.root,
      PATH_DASHBOARD.hr.article.newArticle,
    ])
  }

  // if(role === Roles.hr) {
  //     can('view', [PATH_DASHBOARD.vacancies.root, PATH_DASHBOARD.vacancies.create, PATH_DASHBOARD.vacancies.createSpeciality]);
  // }

  can('view', [
    PATH_DASHBOARD.root,
    PATH_DASHBOARD.user.account,
    PATH_AUTH.login,
    PATH_AUTH.resetPassword,
  ])

  return new Ability(rules as any)
}

// {label: "Администратор", code: "admin"}
// {label: "Маркетолог", code: "marketer"}
// {label: "Аннонсер", code: "announcement"}
// {label: "Рекрутеры", code: "hr"}
// {label: "Специалист поддержки", code: "support"}
// {label: "Клиент", code: "client"}
