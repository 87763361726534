import {
    Box, Button,
    Card,
    FormGroup,
    FormHelperText, Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel, Switch,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {cloneDeep} from "lodash";
import TinyMCEEditor from "../editor/tinymce";
import {createVacanciesItemThunk, editVacanciesItemThunk} from "../../redux/thunks/vacancies";
import {LoadingButton} from "@mui/lab";

type FormValuesProps = {
    title: string;
    description: string;
    active: boolean;
    staticPath: string;
    salary: string;
    mailTo: string;
    copyTo: string;
};

type Props = {
    onSave: () => void;
};

export default function VacanciesEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentVacanciesItem, vacanciesList, specialityList } = useSelector((state: RootState) => state.vacancies);
    const { regionList } = useSelector((state: RootState) => state.contact);

    const isEdit = currentVacanciesItem !== undefined;

    const [description, setDescription] = useState<string>('');
    const [selectedSpecialities, setSelectedSpecialities] = useState<Set<number>>(new Set());
    const [selectedRegions, setSelectedRegions] = useState<Set<number>>(new Set());

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
        staticPath: Yup.string().required('Введите URL страницы'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            description: '',
            active: true,
            staticPath: '',
            salary: '',
            mailTo: '',
            copyTo: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            if(selectedSpecialities.size === 0){
                enqueueSnackbar("Необходимо выбрать хотя бы одну специализацию", { variant: 'error' });
            }
            else {
                setSubmitting(false);
                await submitForm();
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        const newSelectedSpecialities = new Set<number>();
        const newSelectedRegions = new Set<number>();
        if(currentVacanciesItem){
            setFieldValue('title', currentVacanciesItem?.title);
            setFieldValue('description', currentVacanciesItem?.description);
            setFieldValue('staticPath', currentVacanciesItem?.staticPath);
            setFieldValue('active', !!currentVacanciesItem?.active);
            setFieldValue('salary', currentVacanciesItem?.salary);
            setFieldValue('mailTo', currentVacanciesItem?.mailTo);
            setFieldValue('copyTo', currentVacanciesItem?.copyTo);
            currentVacanciesItem?.specializations.forEach(spec => {
                newSelectedSpecialities.add(spec.id);
            });
            currentVacanciesItem?.regions.forEach(region => {
                newSelectedRegions.add(region.id);
            });
            setDescription(currentVacanciesItem?.description);
        }
        else {
            resetForm();
            let defaultInd = 1;
            while(vacanciesList.find(item => item.staticPath === 'vacancy'+defaultInd)){ defaultInd++; }
            setFieldValue('staticPath', 'vacancy'+defaultInd);
            setDescription('');
        }
        setSelectedSpecialities(newSelectedSpecialities);
        setSelectedRegions(newSelectedRegions);
    }, [currentVacanciesItem]);

    const submitForm = async () => {
        const params = {
            ...values,
            active: values.active ? 1 : 0,
            specializations: Array.from(selectedSpecialities),
            regions: Array.from(selectedRegions),
            description,
        }
        const result = isEdit
            ? await dispatch(editVacanciesItemThunk(''+currentVacanciesItem?.id, params))
            : await dispatch(createVacanciesItemThunk(params));

        if(result) {
            resetForm();
            enqueueSnackbar('Вакансия сохранена', {variant: 'success'});
            onSave();
        }
        else {
            enqueueSnackbar("Ошибка сохранения вакансии", { variant: 'error' });
        }
    }

    const handleSpecialityChange = (id: number, checked: boolean) => {
        const newSelectedSpecialities = cloneDeep(selectedSpecialities);
        if(checked){
            newSelectedSpecialities.add(id);
        }
        else {
            newSelectedSpecialities.delete(id);
        }
        setSelectedSpecialities(newSelectedSpecialities);
    }

    const handleRegionChange = (regionId: number, checked: boolean) => {
        const newSelectedRegions = cloneDeep(selectedRegions);
        if(regionId === 0) {
            if(checked) {
                newSelectedRegions.clear();
            }
        }
        else {
            if(checked) {
                newSelectedRegions.add(regionId);
            }
            else {
                newSelectedRegions.delete(regionId);
            }
        }

        setSelectedRegions(newSelectedRegions);
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('salary')}
                                fullWidth
                                type="text"
                                label="Заработная плата"
                                error={Boolean(touched.salary && errors.salary)}
                                helperText={touched.salary && errors.salary}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('mailTo')}
                                fullWidth
                                type="text"
                                label="Отклик отправлять на e-mail"
                                error={Boolean(touched.mailTo && errors.mailTo)}
                                helperText={touched.mailTo && errors.mailTo}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('copyTo')}
                                fullWidth
                                type="text"
                                label="Копию отправлять на e-mail"
                                error={Boolean(touched.copyTo && errors.copyTo)}
                                helperText={touched.copyTo && errors.copyTo}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="URL вакансии"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                            <Box sx={{ mb: 2 }} />
                            <TinyMCEEditor
                                value={values.description}
                                onChange={(val) => setDescription(val)}
                            />
                            {touched.description && errors.description && (
                                <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                    {touched.description && errors.description}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                            <FormGroup>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Специализация:
                                </Typography>
                                {specialityList.map(speciality => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedSpecialities.has(speciality.id)}
                                                onChange={e => handleSpecialityChange(speciality.id, e.target.checked)}
                                            />
                                        }
                                        label={speciality.title}
                                    />
                                ))}
                            </FormGroup>
                            <FormGroup>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Регионы:
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedRegions.size === 0}
                                            onChange={e => handleRegionChange(0, e.target.checked)}
                                        />
                                    }
                                    label="Все города"
                                />
                                {regionList.map(region => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedRegions.has(region.id)}
                                                onChange={e => handleRegionChange(region.id, e.target.checked)}
                                            />
                                        }
                                        label={region.title}
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Card>

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
   );
}