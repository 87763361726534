import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import {getBannerThunk} from "../../../redux/thunks/banners";
import BannersEdit from "../../../components/banners/BannersEdit";
import {getRegionListThunk} from "../../../redux/thunks/contact";

// ----------------------------------------------------------------------

export default function BannersCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, currentBannersItem } = useSelector((state: RootState) => state.banners);

  const { id = 0 } = useParams();
  const isEdit = currentBannersItem !== undefined;

  useEffect(() => {
      dispatch(getBannerThunk(+id));
      dispatch(getRegionListThunk());
  }, [dispatch]);

  const handleSave = () => {
    navigate(PATH_DASHBOARD.banners.root);
  }

  return (
      <DataLoadingPage
        loading={isLoading}
        title='Редактирование баннера'
        heading={!isEdit ? 'Создание баннера' : 'Редактирование баннера'}
        links={[
          { name: 'Главная', href: PATH_DASHBOARD.root },
          { name: 'Баннеры', href: PATH_DASHBOARD.banners.root },
          { name: !isEdit ? 'Новый баннер' : (currentBannersItem?.title || '') }
        ]}
      >
        <BannersEdit
            onSave={handleSave}
        />
      </DataLoadingPage>
  );
}
