import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type ContactState = {
  error: boolean;
};

const initialState: ContactState = {
  error: false,
};

const slice = createSlice({
  name: 'exports',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { hasError } = slice.actions;

export default slice.reducer;
