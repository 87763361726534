// material
import {
  Container, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import AdsTable from "../../../components/ads/AdsTable";

export default function AdsList() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Список объявлений">
      <Container maxWidth={themeStretch ? false : 'lg'}>

        <HeaderBreadcrumbs
          heading="Список объявлений"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Объявления'},
          ]}
          action={
              <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${PATH_DASHBOARD.ads.create}`}
                  startIcon={<Icon icon={plusFill} />}
              >
                Объявление
              </Button>
          }
        />

        <AdsTable />

      </Container>
    </Page>
  );
}
