import {
    Box, Button,
    Card,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState, useCallback} from "react";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {uploadFileThunk} from "../../redux/thunks/files";

import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {useSnackbar} from "notistack";
import {DatePicker, LoadingButton} from "@mui/lab";
import {UploadSingleFile} from "../upload";
import {baseUrl} from "../../utils/axios";
import {createPublicationsItemThunk, editPublicationsItemThunk} from "../../redux/thunks/publications";
import Moment from "moment";

type FormValuesProps = {
    title: string;
    date: Date | null;
    active: boolean;
    staticPath: string;
};

type Props = {
    onSave: () => void;
};

export default function PublicationsEdit({ onSave }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { currentPublicationsItem } = useSelector((state: RootState) => state.publications);

    const isEdit = currentPublicationsItem !== undefined;

    const [file, setFile] = useState<File | string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>(undefined);

    const EditSchema = Yup.object().shape({
        title: Yup.string().required('Введите заголовок'),
    });

    const formik = useFormik<FormValuesProps>({
        initialValues: {
            title: '',
            date: null,
            active: true,
            staticPath: '',
        },
        validationSchema: EditSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const params = {
                ...values,
                active: values.active ? 1 : 0,
                date: values.date ? Moment(values.date).format('YYYY-MM-DD') : null,
                files: fileName !== undefined ? [fileName] : [],
            }
            const result = isEdit
                ? await dispatch(editPublicationsItemThunk(''+currentPublicationsItem?.id, params))
                : await dispatch(createPublicationsItemThunk(params));

            setSubmitting(false);
            if(result) {
                resetForm();
                enqueueSnackbar('Публикация сохранена', {variant: 'success'});
                onSave();
            }
            else {
                enqueueSnackbar("Ошибка сохранения публикации", { variant: 'error' });
            }
        }
    });

    const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

    useEffect(() => {
        if(currentPublicationsItem){
            setFieldValue('title', currentPublicationsItem?.title);
            setFieldValue('date', currentPublicationsItem?.date);
            setFieldValue('staticPath', currentPublicationsItem?.staticPath);
            setFieldValue('active', !!currentPublicationsItem?.active);
            const files = currentPublicationsItem?.files;
            if(files && files?.length > 0){
                const currentFileName = files[0].file;
                setFileName(currentFileName);
                setFile(`${baseUrl}/files/publications/${currentFileName}`);
            }
            else {
                setFile(null);
                setFileName(undefined);
            }
        }
        else {
            resetForm();
            setFile(null);
            setFileName(undefined);
        }
    }, [currentPublicationsItem]);

    const handleDropFile = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const loadedFileName = await dispatch(uploadFileThunk(file));
            if(loadedFileName){
                setFileName(loadedFileName);
                setFile({
                    ...file,
                    preview: URL.createObjectURL(file)
                });
            }
            else {
                enqueueSnackbar("Ошибка загрузки файла", { variant: 'error' });
            }
        }
    }, []);

    const handleRemoveImage = useCallback(() => {
        setFile(null);
        setFileName(undefined);
    }, []);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                {...getFieldProps('title')}
                                fullWidth
                                type="text"
                                label="Заголовок"
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                            />
                            <Box sx={{ mb: 2 }} />
                            <DatePicker
                                label="Дата"
                                {...getFieldProps('date')}
                                onChange={(date) => setFieldValue('date', date)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={Boolean(touched.date && errors.date)}
                                    />
                                )}
                                inputFormat="yyyy-MM-dd"
                            />
                            <Box sx={{ mb: 2 }} />
                            <TextField
                                {...getFieldProps('staticPath')}
                                fullWidth
                                type="text"
                                label="URL статьи"
                                error={Boolean(touched.staticPath && errors.staticPath)}
                                helperText={touched.staticPath && errors.staticPath}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Switch {...getFieldProps('active')} checked={values.active} />}
                                label={
                                    <>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Активность
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                            />
                            <Box sx={{ mb: 2 }} />
                            <UploadSingleFile file={file} onDrop={handleDropFile} />
                            {file && (
                                <Button color="error" onClick={handleRemoveImage}>удалить</Button>
                            )}
                        </Grid>
                    </Grid>
                </Card>

                <Box sx={{ mb: 5 }} />

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Сохранить
                </LoadingButton>
                <Button color="inherit" variant="contained"  sx={{mx: 2}} onClick={onSave}>
                    Отмена
                </Button>
            </Form>
        </FormikProvider>
    );
}