import { useState } from 'react';
// material
import {
  Container, Tabs, Tab, Box, Button
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import {Link as RouterLink} from "react-router-dom";
import {Icon} from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import VacanciesTable from "../../../components/vacancies/VacanciesTable";
import SpecialityTable from "../../../components/vacancies/SpecialityTable";

export default function VacanciesList() {
  const { themeStretch } = useSettings();
  const [currentTab, setCurrentTab] = useState('general');

  const TABS = [
    {
      value: 'general',
      title: 'Вакансии',
      component: <VacanciesTable />
    },
    {
      value: 'speciality',
      title: 'Специализация',
      component: <SpecialityTable />
    }
  ];

  return (
    <Page title="Список вакансий">
      <Container maxWidth={themeStretch ? false : 'lg'}>

        <HeaderBreadcrumbs
          heading="Список вакансий"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Вакансии'},
          ]}
          action={
            <>
            {currentTab === 'speciality' ? (
                <Button
                    variant="contained"
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.vacancies.createSpeciality}`}
                    startIcon={<Icon icon={plusFill} />}
                >
                  Специализация
                </Button>
            ) : (
                <Button
                    variant="contained"
                    component={RouterLink}
                    to={`${PATH_DASHBOARD.vacancies.create}`}
                    startIcon={<Icon icon={plusFill} />}
                >
                  Вакансия
                </Button>
                )}
            </>
          }
        />

        <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
        >
          {TABS.map((tab) => (
              <Tab
                  disableRipple
                  key={tab.value}
                  label={tab.title}
                  value={tab.value}
              />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
