import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React, { Dispatch, SetStateAction } from 'react'
import { IMeta } from '../@types/meta'
import { Box } from '@mui/material'

type Props = {
  meta: IMeta
  page: number
  rowsPerPage: string
  setPage: Dispatch<SetStateAction<number>>
  handleChange: (event: SelectChangeEvent) => void
}

export const TableControl = ({
  meta,
  page,
  handleChange,
  rowsPerPage,
  setPage,
}: Props) => {
  return (
    <>
      <Box sx={{ mb: 5 }} />
      <Grid
        justifyContent="flex-end"
        alignItems="center"
        sx={{ pb: 2, borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        item
        xs={12}
        container
        spacing={2}
        direction="row"
      >
        <Grid item xs={'auto'}>
          <FormControl sx={{ minWidth: 65 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={rowsPerPage}
              onChange={handleChange}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={'auto'}>
          {` Количество ${meta.from ?? 0} - ${meta.to ?? 0} из ${meta.total}`}
        </Grid>

        <Grid item xs={'auto'}>
          <Button
            disabled={page <= 1}
            onClick={() => setPage(prev => prev - 1)}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            disabled={page >= meta.last_page}
            onClick={() => setPage(prev => prev + 1)}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
