import { filter } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { IClient, IClients } from '../../@types/client'

// // ----------------------------------------------------------------------
interface IUserState {
  isLoading: boolean
  error: boolean
  clientFull: IClients
  client: IClient
}

const initialState: IUserState = {
  isLoading: false,
  error: false,
  clientFull: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: '',
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
  client: {
    id: '',
    firstName: '',
    lastName: '',
    surname: '',
    name: '',
    email: '',
    phone: '',
    role: '',
    position: '',
    active: 0,
    is_banned: 0,
  },
}
const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // DELETE USERS
    deleteClient(state, action) {
      const deleteUser = filter(
        state.clientFull.data,
        client => client.id !== action.payload,
      )
      state.clientFull.data = deleteUser
    },

    // GET MANAGE USERS

    getClientListFullSuccess(state, action) {
      state.isLoading = false
      state.clientFull = action.payload
    },
    getClientSuccess(state, action) {
      state.isLoading = false
      state.client = action.payload
    },
  },
})

export const {
  startLoading,
  hasError,
  deleteClient,
  getClientSuccess,
  getClientListFullSuccess,
} = slice.actions

export default slice.reducer
