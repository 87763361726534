import { createSlice } from '@reduxjs/toolkit'
import {
  FullVacanciesItem,
  Speciality,
  VacanciesItem,
} from '../../@types/vacancies'

// ----------------------------------------------------------------------

type VacanciesState = {
  isLoading: boolean
  error: boolean
  vacanciesList: VacanciesItem[]
  currentVacanciesItem?: FullVacanciesItem
  specialityList: Speciality[]
  currentSpeciality?: Speciality
}

const initialState: VacanciesState = {
  isLoading: false,
  error: false,
  vacanciesList: [],
  specialityList: [],
}

const slice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    getVacanciesListSuccess(state, action) {
      state.isLoading = false
      state.vacanciesList = action.payload
    },

    getVacanciesItemSuccess(state, action) {
      state.isLoading = false
      state.currentVacanciesItem = action.payload
    },

    changeVacanciesItemActiveStatus(state, action) {
      const list = state.vacanciesList.map(item => {
        const active =
          item.id !== +action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.vacanciesList = list
    },

    deleteVacanciesItem(state, action) {
      const list = state.vacanciesList.filter(
        item => item.id !== +action.payload,
      )
      state.vacanciesList = list
    },

    getSpecialityListSuccess(state, action) {
      state.isLoading = false
      state.specialityList = action.payload
    },

    getSpecialitySuccess(state, action) {
      state.isLoading = false
      state.currentSpeciality = action.payload
    },

    changeSpecialityActiveStatus(state, action) {
      const list = state.specialityList.map(item => {
        const active =
          item.id !== +action.payload ? item.active : item.active ? 0 : 1
        return {
          ...item,
          active,
        }
      })
      state.specialityList = list
    },

    deleteSpeciality(state, action) {
      const list = state.specialityList.filter(
        item => item.id !== +action.payload,
      )
      state.specialityList = list
    },
  },
})

export const {
  startLoading,
  hasError,
  getVacanciesListSuccess,
  getVacanciesItemSuccess,
  changeVacanciesItemActiveStatus,
  deleteVacanciesItem,
  getSpecialityListSuccess,
  getSpecialitySuccess,
  changeSpecialityActiveStatus,
  deleteSpeciality,
} = slice.actions

export default slice.reducer
