import {useNavigate, useParams} from 'react-router-dom';
//store
import {useEffect} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";

import DataLoadingPage from 'components/DataLoadingPage';
import {PATH_DASHBOARD} from "../../../routes/paths";
import SpecialityEdit from "../../../components/vacancies/SpecialityEdit";
import {getSpecialityThunk} from "../../../redux/thunks/vacancies";

// ----------------------------------------------------------------------

export default function SpecialityCreate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, currentSpeciality } = useSelector((state: RootState) => state.vacancies);

  const { id = 0 } = useParams();
  const isEdit = currentSpeciality !== undefined;

  useEffect(() => {
      dispatch(getSpecialityThunk(+id));
  }, [dispatch]);

  const handleSave = () => {
    navigate(PATH_DASHBOARD.vacancies.root);
  }

  return (
      <DataLoadingPage
        loading={isLoading}
        title='Редактирование специализации'
        heading={!isEdit ? 'Создание специализации' : 'Редактирование специализации'}
        links={[
          { name: 'Главная', href: PATH_DASHBOARD.root },
          { name: 'Вакансии', href: PATH_DASHBOARD.vacancies.root },
          { name: !isEdit ? 'Новая специализация' : (currentSpeciality?.title || '') }
        ]}
      >
        <SpecialityEdit
            onSave={handleSave}
        />
      </DataLoadingPage>
  );
}
