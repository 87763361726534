import { createSlice } from '@reduxjs/toolkit';
import { UserRole } from '../../@types/user-role';

// ----------------------------------------------------------------------

type UserRoles = {
  isLoading: boolean;
  error: boolean;
  userRoles: UserRole[];
};

const initialState: UserRoles = {
  isLoading: false,
  error: false,
  userRoles: []
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS Role
    getUserRoleSuccess(state, action) {
      state.isLoading = false;
      state.userRoles = action.payload;
    },
  },
});

export const { startLoading, hasError, getUserRoleSuccess } = slice.actions;

export default slice.reducer;
