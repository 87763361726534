// material
import { styled } from '@mui/material/styles';
import {Stack, Container, Card} from '@mui/material';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { LoginForm } from '../../components/authentication/login';
import Background from "../../layouts/Background";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Вход в панель управления">
      <Background />
      <AuthLayout>

      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            {/*<Tooltip title={capitalCase(method)}>*/}
            {/*  <Box*/}
            {/*    component="img"*/}
            {/*    src={`/static/auth/ic_${method}.png`}*/}
            {/*    sx={{ width: 32, height: 32 }}*/}
            {/*  />*/}
            {/*</Tooltip>*/}
          </Stack>

          <Card sx={{ p: 3 }}>
              <LoginForm />
          </Card>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
